import * as React from "react";
import Chart from "react-google-charts";
var options = {
    backgroundColor: "#f5f4f2",
    legend: { position: "none" },
    vAxis: {
        title: "Students",
        ticks: [0],
        titleTextStyle: {
            fontSize: "14",
            italic: false,
            color: "#6c757d"
        },
        gridlines: { color: "#6c757d" }
    },
    hAxis: {
        title: "Active Time",
        titleTextStyle: {
            fontSize: "14",
            italic: false,
            color: "#6c757d"
        },
        gridlines: { color: "#6c757d" },
        textStyle: {
            fontSize: "14",
            color: "#6c757d"
        }
    }
};
var CSUActiveTimeChart = function (props) {
    var _a = props.activeTimeStats, greaterThanFortyFiveMinutes = _a.greaterThanFortyFiveMinutes, lessThanThirtyMinutes = _a.lessThanThirtyMinutes, thirtyToFortyFiveMinutes = _a.thirtyToFortyFiveMinutes;
    var _b = React.useState(String), range = _b[0], setRange = _b[1];
    var _c = React.useState(Number), noOfStudents = _c[0], setNoOfStudents = _c[1];
    React.useEffect(function () {
        window.addEventListener("keyup", handleUserKeyPress);
        var chart = document.querySelector(".usage-chart");
        if (!!chart) {
            chart.addEventListener("mouseover", function () { return setShowTooltip(false); });
        }
        return function () {
            window.removeEventListener("keyup", handleUserKeyPress);
            if (!!chart) {
                chart.removeEventListener("mouseover", function () { return setShowTooltip(false); });
            }
        };
    });
    var setShowTooltip = function (isVisible) {
        var element = document.querySelector(".custom-tooltip");
        element.style.setProperty("--tooltip-visibility", isVisible ? "visible" : "hidden");
    };
    var handleUserKeyPress = function (event) {
        var shiftKey = event.shiftKey, keyCode = event.keyCode;
        if ((shiftKey && keyCode === 9) || keyCode === 9) {
            var div = document.querySelector(".App");
            if (document.activeElement === div) {
                setShowTooltip(true);
                getInfo(props.selectedGroup);
            }
            else {
                setShowTooltip(false);
            }
        }
        if (keyCode === 27)
            setShowTooltip(false);
    };
    var tickCount = Math.ceil(Math.max(greaterThanFortyFiveMinutes.length, lessThanThirtyMinutes.length, thirtyToFortyFiveMinutes.length) / 10);
    var getInfo = function (selectedGroup) {
        switch (selectedGroup) {
            case 0: {
                setRange("< 30 MIN");
                setNoOfStudents(lessThanThirtyMinutes.length);
                break;
            }
            case 1: {
                setRange("30-45");
                setNoOfStudents(thirtyToFortyFiveMinutes.length);
                break;
            }
            case 2: {
                setRange("> 45 MIN");
                setNoOfStudents(greaterThanFortyFiveMinutes.length);
                break;
            }
        }
    };
    var handleBarShift = function (e, direction) {
        var selectedGroup = props.selectedGroup, setGroup = props.setGroup;
        var nextGroup = direction === "left" ? selectedGroup - 1 : selectedGroup + 1;
        setShowTooltip(true);
        getInfo(nextGroup);
        setGroup(nextGroup);
        e.preventDefault();
    };
    for (var i = 1; i <= tickCount; i++) {
        options.vAxis.ticks.push(i * 10);
    }
    var chartDivKeyboardCapture = function (e) {
        switch (e.key) {
            case "Right":
            case "ArrowRight": {
                if (props.selectedGroup < 2) {
                    handleBarShift(e, "right");
                }
                else {
                    setShowTooltip(false);
                    props.setGroup(2);
                }
                break;
            }
            case "Left":
            case "ArrowLeft": {
                if (props.selectedGroup > 0) {
                    handleBarShift(e, "left");
                }
                else {
                    setShowTooltip(false);
                    props.setGroup(0);
                }
                break;
            }
            default:
        }
        var div = document.querySelector(".App");
        div.focus();
    };
    var getStyle = function (group) { return (props.selectedGroup === group ? "color:#2e3192" : "color:#6e7075"); };
    return (React.createElement("div", { className: "App", tabIndex: 0, onKeyDownCapture: chartDivKeyboardCapture },
        React.createElement("div", { className: "custom-tooltip" },
            React.createElement("span", { className: "custom-tooltip_bold" }, range),
            React.createElement("br", null),
            "No of students: ",
            React.createElement("span", { className: "custom-tooltip_bold" }, noOfStudents)),
        React.createElement(Chart, { className: "usage-chart", height: "100%", width: "100%", chartType: "ColumnChart", loader: React.createElement("div", null, "Loading Chart"), data: [
                ["Active Time", "No of students", { role: "style" }],
                ["< 30 MIN", lessThanThirtyMinutes.length, getStyle(0)],
                ["30-45", thirtyToFortyFiveMinutes.length, getStyle(1)],
                ["> 45 MIN", greaterThanFortyFiveMinutes.length, getStyle(2)]
            ], options: options, chartEvents: [
                {
                    eventName: "select",
                    callback: function (_a) {
                        var chartWrapper = _a.chartWrapper;
                        var chart = chartWrapper.getChart();
                        var selection = chart.getSelection();
                        var selectedItem = selection[0];
                        setShowTooltip(false);
                        props.setGroup(selectedItem.row);
                    }
                }
            ] })));
};
export default CSUActiveTimeChart;
