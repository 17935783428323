var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { UxTable } from "@wne/ux-component-library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faUsers } from "@fortawesome/pro-solid-svg-icons";
import LessonDetailsDrillDown from "./lesson-details-drilldown";
import { SortState } from "../../common/types";
import getNextSortState from "../../common/get-next-sort-state";
var SortColumn;
(function (SortColumn) {
    SortColumn[SortColumn["Lessons"] = 1] = "Lessons";
    SortColumn[SortColumn["Stfo"] = 2] = "Stfo";
    SortColumn[SortColumn["Default"] = 3] = "Default";
})(SortColumn || (SortColumn = {}));
var LessonDetails = /** @class */ (function (_super) {
    __extends(LessonDetails, _super);
    function LessonDetails(props) {
        var _this = _super.call(this, props) || this;
        _this.getTableRows = function () {
            return _this.props.lessonDetails.map(function (ld) { return ({
                body: (React.createElement(LessonDetailsDrillDown, { students: ld.students, lesson: ld.lesson, getStudentLessonStatus: _this.props.getStudentLessonStatus })),
                lessonsColumn: (React.createElement("div", { className: "detail-name-style" },
                    ld.lesson.name,
                    _this.isAnyLessonInstructedInSpanish(ld) && React.createElement("p", { className: "student-row-spanish-indicator" }, "SPA"))),
                stfoColumn: (React.createElement("div", { className: "just-align-float-center-basis-100 padding-top-8px" },
                    React.createElement("div", { className: ld.numFailed === 0 ? "no-circle" : "circle " + _this.getCircleColorClass(ld) },
                        React.createElement("div", null, ld.numFailed)),
                    " "))
            }); });
        };
        _this.isAnyLessonInstructedInSpanish = function (ld) {
            return ld.students.filter(function (s) { return s.lessonDetails.find(function (sld) { return sld.instructionLanguage.toLocaleLowerCase() === "spanish"; }); })
                .length > 0;
        };
        _this.getCircleColorClass = function (ld) { return (ld.numFailed === 1 ? "orange-circle" : "red-circle"); };
        _this.state = {
            sortState: SortState.Ascending,
            sortColumn: SortColumn.Default
        };
        _this.studentToFocusOnSort();
        return _this;
    }
    LessonDetails.prototype.getHeaders = function () {
        var _this = this;
        var lessonsColumn = (React.createElement("div", { className: "display-flex details-columns-title" },
            React.createElement("span", { className: "padding-before-sort" }, "Lessons"),
            React.createElement("button", { type: "button", title: "Sort", "aria-label": "Sort by lesson name", className: "sort-button", onClick: function () { return _this.sortMethod(SortColumn.Lessons); } },
                React.createElement(FontAwesomeIcon, { icon: faSort }))));
        var stfoColumn = (React.createElement("div", { className: "display-flex-padding-left-5px-center-justify details-columns-title" },
            React.createElement("span", { className: "padding-before-sort" }, "Students To Focus On"),
            React.createElement("button", { type: "button", title: "Sort", "aria-label": "Sort by students to focus on", className: "sort-button", onClick: function () { return _this.sortMethod(SortColumn.Stfo); } },
                React.createElement(FontAwesomeIcon, { icon: faSort }))));
        return [lessonsColumn, stfoColumn];
    };
    LessonDetails.prototype.sortMethod = function (value) {
        var nextState = getNextSortState(value, this.state);
        this.setState(nextState);
        if (nextState.sortState === SortState.NoSort) {
            this.studentToFocusOnSort();
        }
        else {
            this.sortType(value, nextState.sortState);
        }
    };
    LessonDetails.prototype.sortType = function (value, order) {
        switch (value) {
            case SortColumn.Lessons:
                this.lessonNameSort(order);
                break;
            case SortColumn.Stfo:
                this.studentToFocusOnSort(order);
                break;
            default:
                this.studentToFocusOnSort(SortState.Descending);
                break;
        }
    };
    LessonDetails.prototype.lessonNameSort = function (order) {
        this.props.lessonDetails.sort(function (a, b) {
            switch (order) {
                case SortState.Descending:
                    return b.lesson.name.localeCompare(a.lesson.name);
                case SortState.Ascending:
                    return a.lesson.name.localeCompare(b.lesson.name);
                default:
                    return 0;
            }
        });
    };
    LessonDetails.prototype.studentToFocusOnSort = function (order) {
        if (order === void 0) { order = SortState.Descending; }
        this.props.lessonDetails.sort(function (a, b) {
            if (a.numFailed !== b.numFailed) {
                if (order === SortState.Ascending) {
                    return a.numFailed - b.numFailed;
                }
                return b.numFailed - a.numFailed;
            }
            return a.lesson.name.localeCompare(b.lesson.name);
        });
    };
    LessonDetails.prototype.render = function () {
        return (React.createElement("div", { className: "sfo-details-table-wrapper" },
            React.createElement("div", { className: "student-detail-drop-heading" },
                React.createElement("div", null,
                    "Subject:",
                    React.createElement("span", { className: "bold-font-weight" },
                        " ",
                        this.props.subject)),
                React.createElement("div", null,
                    "Time Frame: ",
                    React.createElement("span", { className: "bold-font-weight" }, this.props.timeFrame)),
                React.createElement("div", null,
                    React.createElement(FontAwesomeIcon, { icon: faUsers }),
                    " ",
                    React.createElement("span", { className: "bold-font-weight" }, this.props.className))),
            React.createElement(UxTable, { headings: this.getHeaders(), separated: true, items: this.getTableRows(), openIndicator: true })));
    };
    return LessonDetails;
}(React.Component));
export default LessonDetails;
