import * as React from "react";
import { UxModal, UxSizeExtraEnum } from "@wne/ux-component-library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
var DetailsModal = function (props) { return (React.createElement(UxModal, { size: UxSizeExtraEnum.FULL, showModal: true, showClose: false, className: "csd-ux-modal", scrollable: true, title: React.createElement("div", { className: "csd-details-header" },
        React.createElement("button", { type: "button", title: "Go back", className: "csd-details-back", onClick: function () { return props.onCloseCallback(); } },
            React.createElement(FontAwesomeIcon, { size: "lg", className: "fal", icon: faArrowLeft })),
        React.createElement("div", { className: "csd-details-title" }, props.title)), body: props.body, handleClose: function () {
        // do nothing
    } })); };
export default DetailsModal;
