var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { gradeToInt } from "../common/csd-utils";
import SfoStats from "./sfo-stats";
import StudentStats from "./student-stats";
import StudentsByUsageStat from "./students-by-usage-stat";
import UsageStats from "./usage-stats";
import { msToMin } from "../common/datetime-utils";
export var LessonStatus;
(function (LessonStatus) {
    LessonStatus[LessonStatus["Passed"] = 1] = "Passed";
    LessonStatus[LessonStatus["Failed"] = 2] = "Failed";
    LessonStatus[LessonStatus["InProgress"] = 3] = "InProgress";
})(LessonStatus || (LessonStatus = {}));
var ClassSummaryLocalModel = /** @class */ (function () {
    function ClassSummaryLocalModel(serverModel) {
        var _this = this;
        // A Student array reducer that accumulates counts for passed and failed lessons, and total active time
        this.studentStatsReducer = function (accumulator, lesson) {
            switch (lesson.status) {
                case 1:
                    accumulator.passed += 1;
                    break;
                case 2:
                    accumulator.failed += 1;
                    break;
                default:
                // bad status
            }
            accumulator.activeTimeMins += lesson.activeTimeMs;
            return accumulator;
        };
        // A reducer that accumulates student stats by studentId
        this.statsByStudentReducer = function (accumulator, student) {
            accumulator[student.id] = student.lessonDetails.reduce(_this.studentStatsReducer, new StudentStats(student.id));
            accumulator[student.id].activeTimeMins = msToMin(accumulator[student.id].activeTimeMins);
            return accumulator;
        };
        this.studentsByUsageStatReducer = function (accumulator, studentStats) {
            var student = _this.getStudent(studentStats.studentId);
            if (studentStats.activeTimeMins >= 45) {
                accumulator.greaterThanFortyFiveMinutes.push({
                    student: student,
                    activeTime: studentStats.activeTimeMins
                });
            }
            else if (studentStats.activeTimeMins >= 30) {
                accumulator.thirtyToFortyFiveMinutes.push({
                    student: student,
                    activeTime: studentStats.activeTimeMins
                });
            }
            else {
                accumulator.lessThanThirtyMinutes.push({
                    student: student,
                    activeTime: studentStats.activeTimeMins
                });
            }
            return accumulator;
        };
        // A Reducer that accumulates SfoStats from StudentStatsByStudent
        this.sfoStatsReducer = function (accumulator, studentStats) {
            if (!studentStats.failed) {
                if (studentStats.passed) {
                    accumulator.allPassed += 1;
                }
                else {
                    accumulator.inactive += 1;
                }
            }
            else if (studentStats.failed === 1) {
                accumulator.failedOne += 1;
            }
            else {
                accumulator.failedMore += 1;
            }
            return accumulator;
        };
        // A Reducer that accumulates UsageStats from StudentStatsByStudent
        this.usageStatsReducer = function (accumulator, studentStats) {
            if (studentStats.activeTimeMins >= 45) {
                accumulator.greaterThanFortyFiveMinutes += 1;
            }
            else if (studentStats.activeTimeMins >= 30) {
                accumulator.thirtyToFortyFiveMinutes += 1;
            }
            else {
                accumulator.lessThanThirtyMinutes += 1;
            }
            return accumulator;
        };
        // A reducer that takes an Array of Student, Lesson and returns an Array with Students Grouped
        // by completed lessons (In progress lessons are ignored)
        this.lfoReducer = function (accumulator, lessonstofocuson) {
            var lessonId = lessonstofocuson.lessonId, lessonStatus = lessonstofocuson.lessonStatus, studentId = lessonstofocuson.studentId, standardCodes = lessonstofocuson.standardCodes;
            var studentWithLesson = accumulator.find(function (swl) { return swl.lesson.id === lessonId; });
            var student = _this.getStudent(studentId);
            var lesson = __assign(__assign({}, _this.getLesson(lessonId)), { standardCodes: standardCodes });
            if (!studentWithLesson && lessonStatus !== LessonStatus.InProgress) {
                var failedCount = lessonStatus === LessonStatus.Failed ? 1 : 0;
                accumulator.push({
                    lesson: lesson,
                    students: [student],
                    numFailed: failedCount
                });
            }
            else if (studentWithLesson && lessonStatus !== LessonStatus.InProgress) {
                studentWithLesson.students.push(student);
                studentWithLesson.lesson = lesson;
                if (lessonStatus === LessonStatus.Failed)
                    studentWithLesson.numFailed += 1;
            }
            else {
                return accumulator;
            }
            return accumulator;
        };
        this.serverModel = serverModel;
        this.numStudents = Object.values(serverModel.students).length;
    }
    ClassSummaryLocalModel.prototype.getCourse = function () {
        return this.serverModel.course;
    };
    ClassSummaryLocalModel.prototype.getTeachers = function () {
        return this.serverModel.teachers;
    };
    ClassSummaryLocalModel.prototype.getSubjects = function () {
        return this.serverModel.subjects;
    };
    ClassSummaryLocalModel.prototype.getTimeFrames = function () {
        return this.serverModel.timeFrames;
    };
    ClassSummaryLocalModel.prototype.getActiveSubject = function () {
        var _this = this;
        return this.serverModel.subjects.filter(function (subject) { return subject.id === _this.serverModel.activeSubjectId; }).pop();
    };
    ClassSummaryLocalModel.prototype.getActiveTimeFrame = function () {
        var _this = this;
        return this.serverModel.timeFrames.filter(function (timeFrame) { return timeFrame.id === _this.serverModel.activeTimeFrameId; }).pop();
    };
    ClassSummaryLocalModel.prototype.getNumStudents = function () {
        return this.numStudents;
    };
    ClassSummaryLocalModel.prototype.getAllStudents = function () {
        return Object.values(this.serverModel.students).filter(function (student) { return student.ilpStatus === "Active" && student.studentStatus === "Active"; });
    };
    ClassSummaryLocalModel.prototype.getAllActiveStudents = function () {
        var students = this.getAllStudents();
        return students.filter(function (student) { return student.ilpStatus === "Active" && student.studentStatus === "Active"; });
    };
    ClassSummaryLocalModel.prototype.getStudent = function (studentId) {
        var student = this.serverModel.students[studentId];
        return (student || {
            id: "invalid",
            firstName: "",
            lastName: "",
            ilpGrade: "",
            ilpStatus: "",
            studentStatus: "",
            lessonDetails: []
        });
    };
    ClassSummaryLocalModel.prototype.getStudentStatsByStudent = function () {
        if (!this.studentStats) {
            this.studentStats = this.getAllActiveStudents().reduce(this.statsByStudentReducer, {});
        }
        return this.studentStats;
    };
    ClassSummaryLocalModel.prototype.getStudentStats = function (studentId) {
        return this.getStudentStatsByStudent()[studentId];
    };
    ClassSummaryLocalModel.prototype.getSfoStats = function () {
        if (!this.sfoStats) {
            this.sfoStats = Object.values(this.getStudentStatsByStudent()).reduce(this.sfoStatsReducer, new SfoStats());
        }
        return this.sfoStats;
    };
    ClassSummaryLocalModel.prototype.getUsageStats = function () {
        if (!this.usageStats) {
            this.usageStats = Object.values(this.getStudentStatsByStudent()).reduce(this.usageStatsReducer, new UsageStats());
        }
        return this.usageStats;
    };
    ClassSummaryLocalModel.prototype.getStudentsByUsageStat = function () {
        this.studentsByUsageStat = Object.values(this.getStudentStatsByStudent()).reduce(this.studentsByUsageStatReducer, new StudentsByUsageStat());
        return this.studentsByUsageStat;
    };
    ClassSummaryLocalModel.prototype.getAllLessons = function () {
        return Object.values(this.serverModel.lessons);
    };
    ClassSummaryLocalModel.prototype.getLesson = function (lessonId) {
        var lesson = this.serverModel.lessons[lessonId];
        return (lesson || {
            id: "invalid",
            gradeLevel: "",
            name: "",
            domain: ""
        });
    };
    // Get all lessons with associated student list and flatten it into a {lesssonId,studentId} object
    ClassSummaryLocalModel.prototype.getStudentLessonStatuses = function () {
        var students = Object.values(this.serverModel.students);
        return students
            .filter(function (student) { return student.ilpStatus === "Active" && student.studentStatus === "Active"; })
            .flatMap(function (student) {
            return student.lessonDetails.map(function (lesson) { return ({
                lessonId: lesson.lessonId,
                studentId: student.id,
                lessonStatus: lesson.status,
                instructionLanguage: lesson.instructionLanguage,
                standardCodes: lesson.standardCodes
            }); });
        });
    };
    // Gets Students Grouped by completed Lessons along with count of students who have failed that lesson
    ClassSummaryLocalModel.prototype.getCompletedLessonsWithStudents = function () {
        var sortByGradeLevel = function (gradeLevelA, gradeLevelB) {
            return gradeToInt(gradeLevelA) - gradeToInt(gradeLevelB);
        };
        return this.getStudentLessonStatuses()
            .reduce(this.lfoReducer, [])
            .sort(function (a, b) { return b.numFailed - a.numFailed || sortByGradeLevel(a.lesson.gradeLevel, b.lesson.gradeLevel); });
    };
    ClassSummaryLocalModel.prototype.getStudentLessonStatus = function (studentId, lessonId) {
        var studentLessons = Object.values(this.serverModel.students[studentId].lessonDetails);
        var lessonDetail = studentLessons.filter(function (l) { return l.lessonId === lessonId; });
        return lessonDetail[0].status;
    };
    ClassSummaryLocalModel.prototype.getStudentLessonInstructionLanguage = function (studentId, lessonId) {
        var _a;
        var studentLessons = Object.values(this.serverModel.students[studentId].lessonDetails);
        var lessonDetail = studentLessons.filter(function (l) { return l.lessonId === lessonId; });
        return (_a = lessonDetail.pop()) === null || _a === void 0 ? void 0 : _a.instructionLanguage;
    };
    return ClassSummaryLocalModel;
}());
export { ClassSummaryLocalModel };
