import getLoadFeaturesUrl from "./get-load-features-url";
var retryAfter = 1000;
var retriesLeft = 3;
var loadFeatures = function (callback) {
    var flagrUrl = getLoadFeaturesUrl();
    if (flagrUrl) {
        var flagsEvaluationBody = {
            entities: [{ entityId: "FeatureLoader" }],
            enableDebug: true,
            flagTags: ["RAD"],
            flagTagsOperator: "ANY"
        };
        var requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(flagsEvaluationBody)
        };
        fetch(flagrUrl, requestOptions)
            .then(function (response) {
            if (response.ok) {
                return response.json();
            }
            throw new Error(response.statusText);
        })
            .then(function (data) {
            var results = data === null || data === void 0 ? void 0 : data.evaluationResults;
            if (results) {
                var keys = results.filter(function (item) { return item.segmentID; }).map(function (item) { return item.flagKey; });
                callback(keys);
            }
            else {
                throw new Error("Invalid features data");
            }
        })
            .catch(function (error) {
            if (retriesLeft <= 0) {
                callback(["features-load-error", "" + error]);
            }
            else {
                retriesLeft -= 1;
                setTimeout(function () {
                    loadFeatures(callback);
                }, retryAfter);
            }
        });
    }
    else {
        callback(["no-features-url"]);
    }
};
export default loadFeatures;
