var model = {
    course: {
        id: "3b4faf9e-8200-477a-8a8f-0ebd25009606",
        name: "mytestclass_resource",
        state: "TX"
    },
    teachers: [
        {
            id: "7281e0f2-bbb6-46aa-a8c4-458a8d6f668e",
            firstName: "Teacher1_12July",
            lastName: "Anaya"
        }
    ],
    subjects: [
        {
            id: "math",
            name: "Math"
        },
        {
            id: "ela",
            name: "Reading"
        }
    ],
    timeFrames: [
        {
            id: 0,
            name: "Last 7 Days"
        },
        {
            id: 1,
            name: "Today"
        }
    ],
    activeSubjectId: "math",
    activeTimeFrameId: 0,
    students: {
        "375865d9-0d99-4a87-bcee-54b6ccafdd53": {
            ilpGrade: "8",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [
                {
                    lessonId: "14305",
                    status: 1,
                    activeTimeMs: 125000,
                    score: 80,
                    gradeLevel: "2",
                    instructionLanguage: "English",
                    standardCodes: ["111.3.b.3.A.", "111.3.b.3.E.", "111.3.b.5.G."]
                },
                {
                    lessonId: "14306",
                    status: 1,
                    activeTimeMs: 120000,
                    score: 100,
                    gradeLevel: "2",
                    instructionLanguage: "English",
                    standardCodes: ["111.3.b.3.A."]
                }
            ],
            id: "375865d9-0d99-4a87-bcee-54b6ccafdd53",
            firstName: "Aiden",
            lastName: "Anaya"
        },
        "31dc4add-d777-497f-b10c-be673585ba72": {
            ilpGrade: "6",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [
                {
                    lessonId: "14306",
                    status: 1,
                    activeTimeMs: 60000,
                    score: 100,
                    gradeLevel: "1",
                    instructionLanguage: "English",
                    standardCodes: ["111.3.b.5.G."]
                }
            ],
            id: "31dc4add-d777-497f-b10c-be673585ba72",
            firstName: "Student Resource1",
            lastName: "Anaya"
        },
        "1f23ec52-a51b-492b-8e89-95496520221e": {
            ilpGrade: "7",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [
                {
                    lessonId: "14306",
                    status: 1,
                    activeTimeMs: 60000,
                    score: 100,
                    gradeLevel: "1",
                    instructionLanguage: "English",
                    standardCodes: ["111.3.b.3.A.", "111.3.b.3.E.", "111.3.b.5.G."]
                },
                {
                    lessonId: "14305",
                    status: 2,
                    activeTimeMs: 0,
                    score: 0,
                    gradeLevel: "1",
                    instructionLanguage: "English",
                    standardCodes: ["111.3.b.3.A.", "111.3.b.5.G."]
                },
                {
                    lessonId: "14304",
                    status: 2,
                    activeTimeMs: 60000,
                    score: 0,
                    gradeLevel: "1",
                    instructionLanguage: "English",
                    standardCodes: ["111.3.b.3.A.", "111.3.b.3.E."]
                }
            ],
            id: "1f23ec52-a51b-492b-8e89-95496520221e",
            firstName: "Student Resource6",
            lastName: "Anaya"
        },
        "e525b775-68a2-447e-9a1b-3a46007ff3c5": {
            ilpGrade: "6",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [],
            id: "e525b775-68a2-447e-9a1b-3a46007ff3c5",
            firstName: "Student Resource5",
            lastName: "Anaya"
        },
        "59626729-cde3-458b-a1d8-9ff007cb6826": {
            ilpGrade: "6",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [
                {
                    lessonId: "14348",
                    status: 1,
                    activeTimeMs: 600000,
                    score: 80,
                    gradeLevel: "5",
                    instructionLanguage: "English",
                    standardCodes: ["111.3.b.3.E."]
                }
            ],
            id: "59626729-cde3-458b-a1d8-9ff007cb6826",
            firstName: "Student Resource4",
            lastName: "Anaya"
        },
        "f2dc9ffe-6fa4-4244-9896-a7959c0ed017": {
            ilpGrade: "6",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [
                {
                    lessonId: "14337",
                    status: 1,
                    activeTimeMs: 720000,
                    score: 80,
                    gradeLevel: "4",
                    instructionLanguage: "English",
                    standardCodes: ["111.3.b.3.A.", "111.3.b.3.E.", "111.3.b.5.G."]
                },
                {
                    lessonId: "14328",
                    status: 1,
                    activeTimeMs: 0,
                    score: 100,
                    gradeLevel: "4",
                    instructionLanguage: "English",
                    standardCodes: ["111.3.b.5.G."]
                },
                {
                    lessonId: "14327",
                    status: 1,
                    activeTimeMs: 60000,
                    score: 100,
                    gradeLevel: "4",
                    instructionLanguage: "English",
                    standardCodes: ["111.3.b.3.A."]
                },
                {
                    lessonId: "14326",
                    status: 2,
                    activeTimeMs: 0,
                    score: 0,
                    gradeLevel: "4",
                    instructionLanguage: "English",
                    standardCodes: ["111.3.b.3.A.", "111.3.b.3.E.", "111.3.b.5.G."]
                }
            ],
            id: "f2dc9ffe-6fa4-4244-9896-a7959c0ed017",
            firstName: "Student Resource3",
            lastName: "Anaya"
        },
        "551617fe-8459-40a8-8d71-a2a27f6892eb": {
            ilpGrade: "9",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [],
            id: "551617fe-8459-40a8-8d71-a2a27f6892eb",
            firstName: "Student Resource20",
            lastName: "Anaya"
        },
        "031a73ed-7ead-4d9c-b157-30e76e0da360": {
            ilpGrade: "6",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [
                {
                    lessonId: "14306",
                    status: 1,
                    activeTimeMs: 60000,
                    score: 80,
                    gradeLevel: "2",
                    instructionLanguage: "English",
                    standardCodes: ["111.3.b.3.A.", "111.3.b.3.E.", "111.3.b.5.G."]
                }
            ],
            id: "031a73ed-7ead-4d9c-b157-30e76e0da360",
            firstName: "Student Resource2",
            lastName: "Anaya"
        },
        "2f3c8443-fc2b-4b18-a8e2-1c3054b6842c": {
            ilpGrade: "9",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [],
            id: "2f3c8443-fc2b-4b18-a8e2-1c3054b6842c",
            firstName: "Student Resource18",
            lastName: "Anaya"
        },
        "72f5fa71-99f6-422e-810a-94a41675ef01": {
            ilpGrade: "9",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [],
            id: "72f5fa71-99f6-422e-810a-94a41675ef01",
            firstName: "Student Resource17",
            lastName: "Anaya"
        },
        "05486c3c-ff88-45e8-92ac-3e51db144789": {
            ilpGrade: "9",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [],
            id: "05486c3c-ff88-45e8-92ac-3e51db144789",
            firstName: "Student Resource16",
            lastName: "Anaya"
        },
        "3d30def9-64bf-4991-8122-43a3a02fb68c": {
            ilpGrade: "8",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [],
            id: "3d30def9-64bf-4991-8122-43a3a02fb68c",
            firstName: "Student Resource15",
            lastName: "Anaya"
        },
        "a0102ddf-ca31-481f-ad3a-b52ecd8aa7c0": {
            ilpGrade: "8",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [],
            id: "a0102ddf-ca31-481f-ad3a-b52ecd8aa7c0",
            firstName: "Student Resource14",
            lastName: "Anaya"
        },
        "0151555d-81bd-4591-9fcb-287a19d8350f": {
            ilpGrade: "8",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [],
            id: "0151555d-81bd-4591-9fcb-287a19d8350f",
            firstName: "Student Resource13",
            lastName: "Anaya"
        },
        "4098f101-2c9a-4cfb-a5c4-6d4aef29473a": {
            ilpGrade: "8",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [],
            id: "4098f101-2c9a-4cfb-a5c4-6d4aef29473a",
            firstName: "Student Resource12",
            lastName: "Anaya"
        },
        "c307e1b1-a17d-4bb1-ac7d-39644a0ca690": {
            ilpGrade: "8",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [
                {
                    lessonId: "14306",
                    status: 1,
                    activeTimeMs: 300000,
                    score: 80,
                    gradeLevel: "1",
                    instructionLanguage: "English",
                    standardCodes: ["111.3.b.5.G."]
                }
            ],
            id: "c307e1b1-a17d-4bb1-ac7d-39644a0ca690",
            firstName: "Student Resource11",
            lastName: "Anaya"
        },
        "832deff4-e58e-488d-85a8-b3d78da67ce4": {
            ilpGrade: "7",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [
                {
                    lessonId: "20723",
                    status: 3,
                    activeTimeMs: 300000000,
                    score: 0,
                    gradeLevel: "7",
                    instructionLanguage: "English",
                    standardCodes: ["111.3.b.3.E."]
                }
            ],
            id: "832deff4-e58e-488d-85a8-b3d78da67ce4",
            firstName: "Student Resource10",
            lastName: "Anaya"
        },
        "e9123c1f-fc24-4585-a005-d7ebf7676bbe": {
            ilpGrade: "7",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [
                {
                    lessonId: "14306",
                    status: 3,
                    activeTimeMs: 300000000,
                    score: 0,
                    gradeLevel: "2",
                    instructionLanguage: "English",
                    standardCodes: ["111.3.b.3.A.", "111.3.b.3.E."]
                }
            ],
            id: "e9123c1f-fc24-4585-a005-d7ebf7676bbe",
            firstName: "Student Resource7",
            lastName: "Anaya"
        },
        "bfddb2ff-66da-4970-8ce0-362f152a04ff": {
            ilpGrade: "7",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [
                {
                    lessonId: "14348",
                    status: 3,
                    activeTimeMs: 300000000,
                    score: 0,
                    gradeLevel: "5",
                    instructionLanguage: "English",
                    standardCodes: ["111.3.b.3.A.", "111.3.b.3.E.", "111.3.b.5.G."]
                }
            ],
            id: "bfddb2ff-66da-4970-8ce0-362f152a04ff",
            firstName: "Student Resource9",
            lastName: "Anaya"
        },
        "c463f2d6-ec04-443c-b608-a79ff28f0717": {
            ilpGrade: "9",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [],
            id: "c463f2d6-ec04-443c-b608-a79ff28f0717",
            firstName: "Student Resource19",
            lastName: "Anaya"
        },
        "f7015d09-2094-4eac-bb78-ca0591ee3e7b": {
            ilpGrade: "7",
            ilpStatus: "Active",
            studentStatus: "Active",
            lessonDetails: [
                {
                    lessonId: "14337",
                    status: 3,
                    activeTimeMs: 300000000,
                    score: 0,
                    gradeLevel: "4",
                    instructionLanguage: "English",
                    standardCodes: ["111.3.b.5.G."]
                }
            ],
            id: "f7015d09-2094-4eac-bb78-ca0591ee3e7b",
            firstName: "Student Resource8",
            lastName: "Anaya"
        }
    },
    lessons: {
        "14305": {
            id: "14305",
            name: "The Place Value System of Whole Numbers",
            domain: "Number and Operations",
            gradeLevel: "2"
        },
        "14306": {
            id: "14306",
            name: "Modeling and Comparing Numbers to 120",
            domain: "Number and Operations",
            gradeLevel: "2"
        },
        "14304": {
            id: "14304",
            name: "Composing, Decomposing, and Ordering Numbers Using Place Value",
            domain: "Number and Operations",
            gradeLevel: "1"
        },
        "14348": {
            id: "14348",
            name: "Using Equivalent Fractions to Add and Subtract Fractions",
            domain: "Number and Operations",
            gradeLevel: "5"
        },
        "14337": {
            id: "14337",
            name: "Place Value Concepts up to 1,000,000,000",
            domain: "Number and Operations",
            gradeLevel: "4"
        },
        "14328": {
            id: "14328",
            name: "Benchmark Fractions",
            domain: "Number and Operations",
            gradeLevel: "4"
        },
        "14327": {
            id: "14327",
            name: "Comparing Fractions via Benchmark Fractions",
            domain: "Number and Operations",
            gradeLevel: "4"
        },
        "14326": {
            id: "14326",
            name: "Using Equivalent Fractions to Compare Fractions",
            domain: "Number and Operations",
            gradeLevel: "4"
        },
        "20723": {
            id: "20723",
            name: "Solving Problems Involving Rational Numbers",
            domain: "Number and Operations",
            gradeLevel: "7"
        }
    }
};
export default model;
