var SfoStats = /** @class */ (function () {
    function SfoStats() {
        this.allPassed = 0;
        this.failedOne = 0;
        this.failedMore = 0;
        this.inactive = 0;
    }
    return SfoStats;
}());
export default SfoStats;
