var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import { UxTable } from "@wne/ux-component-library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSort, faUsers } from "@fortawesome/pro-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { LessonStatus } from "../model/class-summary-local-model";
import { SortState } from "../common/types";
var SortColumn;
(function (SortColumn) {
    SortColumn[SortColumn["Name"] = 1] = "Name";
    SortColumn[SortColumn["Ltfo"] = 2] = "Ltfo";
    SortColumn[SortColumn["Passed"] = 3] = "Passed";
    SortColumn[SortColumn["ActiveTime"] = 4] = "ActiveTime";
    SortColumn[SortColumn["Default"] = 5] = "Default";
})(SortColumn || (SortColumn = {}));
var UsageDetail = /** @class */ (function (_super) {
    __extends(UsageDetail, _super);
    function UsageDetail(props) {
        var _this = _super.call(this, props) || this;
        _this.getHeaders = function () {
            library.add(faSort);
            var ldStudentNameColumn = (React.createElement("div", { className: "display-flex-padding-left-5px details-columns-title" },
                "Student Name \u00A0",
                React.createElement("button", { type: "button", title: "Sort", className: "sort-button", onClick: function () { return _this.sortMethod(SortColumn.Name); } },
                    React.createElement(FontAwesomeIcon, { icon: faSort }))));
            var ldPassedColumn = (React.createElement("div", { className: "display-flex-padding-left-5px-center-justify details-columns-title" },
                "Passed Lessons \u00A0",
                React.createElement("button", { type: "button", title: "Sort", className: "sort-button", onClick: function () { return _this.sortMethod(SortColumn.Passed); } },
                    React.createElement(FontAwesomeIcon, { icon: faSort }))));
            var ldLfoColumn = (React.createElement("div", { className: "display-flex-padding-left-5px-center-justify details-columns-title" },
                "Lessons To Focus On \u00A0",
                React.createElement("button", { type: "button", title: "Sort", className: "sort-button", onClick: function () { return _this.sortMethod(SortColumn.Ltfo); } },
                    React.createElement(FontAwesomeIcon, { icon: faSort }))));
            var ldActiveTimeColumn = (React.createElement("div", { className: "display-flex-padding-left-5px-center-justify details-columns-title" },
                "Active Time \u00A0",
                React.createElement("button", { type: "button", title: "Sort", className: "sort-button", onClick: function () { return _this.sortMethod(SortColumn.ActiveTime); } },
                    React.createElement(FontAwesomeIcon, { icon: faSort }))));
            return [ldStudentNameColumn, ldPassedColumn, ldLfoColumn, ldActiveTimeColumn];
        };
        _this.getTableRows = function () {
            return _this.props.studentUsageStats.map(function (usage) { return ({
                ldStudentNameColumn: (React.createElement("div", { className: "usage-detail-data" },
                    usage.student.firstName,
                    " ",
                    usage.student.lastName)),
                ldPassedColumn: (React.createElement("div", { className: "just-align-float-center-basis-100 usage-detail-data" }, usage.student.lessonDetails.filter(function (ld) { return ld.status === LessonStatus.Passed; }).length)),
                ldLfoColumn: (React.createElement("div", { className: "just-align-float-center-basis-100 usage-detail-data" }, usage.student.lessonDetails.filter(function (ld) { return ld.status === LessonStatus.Failed; }).length)),
                ldActiveTimeColumn: React.createElement("div", { className: "usage-detail-data usage-detail-active-time" },
                    usage.activeTime,
                    " Min")
            }); });
        };
        _this.getFailedLessons = function (studentId) {
            var stats = _this.props.statsByStudent[studentId];
            return stats.failed;
        };
        _this.getPassedLessons = function (studentId) {
            var stats = _this.props.statsByStudent[studentId];
            return stats.passed;
        };
        _this.state = {
            sortState: SortState.Ascending,
            sortColumn: SortColumn.Default
        };
        _this.defaultSort();
        return _this;
    }
    UsageDetail.prototype.sortType = function (value, order) {
        if (value === SortColumn.Name) {
            this.studentNameSort(order);
        }
        else {
            this.sortNumber(value, order);
        }
    };
    UsageDetail.prototype.sortMethod = function (value) {
        var prevSortState = this.state.sortColumn === value ? this.state.sortState : SortState.NoSort;
        switch (prevSortState) {
            case SortState.NoSort:
                this.setState({ sortColumn: value, sortState: SortState.Ascending });
                this.sortType(value, SortState.Ascending);
                break;
            case SortState.Ascending:
                this.setState({ sortColumn: value, sortState: SortState.Descending });
                this.sortType(value, SortState.Descending);
                break;
            default:
                this.setState({ sortColumn: value, sortState: SortState.NoSort });
                this.defaultSort();
                break;
        }
    };
    UsageDetail.prototype.sortNumber = function (value, order) {
        var _this = this;
        var getColumn = function (usage) {
            switch (value) {
                case SortColumn.Passed:
                    return _this.getPassedLessons(usage.student.id);
                case SortColumn.Ltfo:
                    return _this.getFailedLessons(usage.student.id);
                case SortColumn.ActiveTime:
                    return usage.activeTime;
                default:
                    return 0;
            }
        };
        this.props.studentUsageStats.sort(function (a, b) {
            if (order === SortState.Ascending) {
                return getColumn(a) - getColumn(b);
            }
            return getColumn(b) - getColumn(a);
        });
    };
    UsageDetail.prototype.studentNameSort = function (order) {
        this.props.studentUsageStats.sort(function (a, b) {
            var condition = function (first, second) {
                switch (order) {
                    case SortState.Descending:
                        return first.toLowerCase() > second.toLowerCase();
                    case SortState.Ascending:
                        return first.toLowerCase() < second.toLowerCase();
                    default:
                        return false;
                }
            };
            if (condition(a.student.lastName, b.student.lastName)) {
                return -1;
            }
            if (a.student.lastName.toLowerCase() === b.student.lastName.toLowerCase()) {
                if (condition(a.student.firstName, b.student.firstName)) {
                    return -1;
                }
                return 0;
            }
            return 1;
        });
    };
    UsageDetail.prototype.defaultSort = function () {
        var _this = this;
        var getFailedLessons = function (studentId) {
            var stats = _this.props.statsByStudent[studentId];
            return stats.failed;
        };
        this.props.studentUsageStats.sort(function (a, b) {
            if (a.activeTime !== b.activeTime) {
                return a.activeTime - b.activeTime;
            }
            if (getFailedLessons(b.student.id) !== getFailedLessons(a.student.id)) {
                return getFailedLessons(b.student.id) - getFailedLessons(a.student.id);
            }
            if (a.student.lastName.toLowerCase() < b.student.lastName.toLowerCase()) {
                return -1;
            }
            if (a.student.lastName.toLowerCase() > b.student.lastName.toLowerCase()) {
                return 1;
            }
            if (a.student.lastName.toLowerCase() === b.student.lastName.toLowerCase()) {
                if (a.student.firstName.toLowerCase() < b.student.firstName.toLowerCase()) {
                    return -1;
                }
                return 1;
            }
            return 0;
        });
    };
    UsageDetail.prototype.render = function () {
        return (React.createElement("div", { className: "sfo-details-table-wrapper" },
            React.createElement("div", { className: "student-detail-drop-heading" },
                React.createElement("div", null,
                    "Subject:",
                    React.createElement("span", { className: "bold-font-weight" },
                        " ",
                        this.props.subject)),
                React.createElement("div", null,
                    "Time Frame: ",
                    React.createElement("span", { className: "bold-font-weight" }, this.props.timeFrame)),
                React.createElement("div", null,
                    React.createElement(FontAwesomeIcon, { icon: faUsers }),
                    " ",
                    "  ",
                    React.createElement("span", { className: "bold-font-weight" }, this.props.className))),
            React.createElement(UxTable, { headings: this.getHeaders(), separated: true, items: this.getTableRows(), openIndicator: true })));
    };
    return UsageDetail;
}(React.Component));
export default UsageDetail;
