var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { useLocation } from "react-router-dom";
import { UxModal, UxError, UxLoadingScreen, UxSizeExtraEnum } from "@wne/ux-component-library";
import { RadService, ServiceStatusEnum } from "@wne/rad-api-service";
import { ClassSummaryLocalModel } from "./model/class-summary-local-model";
import testModelData from "./model/class-summary-api-model-data";
import ClassSummaryContainer from "./class-summary-container";
import { getTimeFrameDates } from "./common/datetime-utils";
import loadFeatures from "./common/load-features";
var GetRadSubjectId = function () {
    var _a, _b;
    var location = useLocation();
    var subject = (_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.subject) === null || _b === void 0 ? void 0 : _b.trim().toLowerCase();
    return subject === "reading" ? "ela" : subject;
};
var GetActiveSubjectId = function (model) { var _a; return ((_a = model === null || model === void 0 ? void 0 : model.getActiveSubject()) === null || _a === void 0 ? void 0 : _a.id) || "unknown"; };
var IsModelLoaded = function (apiState) { return apiState && apiState.status === ServiceStatusEnum.LOADED; };
var DoesModelNeedToBeLoaded = function (apiModelLoadStatus) {
    return !apiModelLoadStatus.modelLoaded && !apiModelLoadStatus.modelLoading;
};
var ClassSummaryConnect = function (props) {
    var radSubjectId = GetRadSubjectId();
    var dateRange = getTimeFrameDates(6);
    var _a = React.useState({
        subjectId: radSubjectId,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        timeFrameId: 0
    }), filters = _a[0], setFilters = _a[1];
    var _b = React.useState([]), features = _b[0], setFeatures = _b[1];
    var _c = React.useState(null), model = _c[0], setModel = _c[1];
    var _d = React.useState(0), dataClicks = _d[0], setDataClicks = _d[1];
    var _e = React.useState({
        modelLoaded: false,
        modelLoading: false,
        apiFailure: false
    }), apiModelLoadStatus = _e[0], setapiModelLoadStatus = _e[1];
    var refreshModel = function () {
        setapiModelLoadStatus(function (previousStatus) { return (__assign(__assign({}, previousStatus), { modelLoaded: false })); });
    };
    var handleSubjectChange = function (subjectId) {
        setFilters(function (previousFilter) { return (__assign(__assign({}, previousFilter), { subjectId: subjectId })); });
        window.sessionStorage.setItem("sessionSubjectId", subjectId);
        refreshModel();
    };
    var handleTimeFrameChange = function (startDate, endDate, timeFrameId) {
        setFilters(function (previousFilter) { return (__assign(__assign({}, previousFilter), { startDate: startDate, endDate: endDate, timeFrameId: timeFrameId })); });
        refreshModel();
    };
    React.useEffect(function () {
        loadFeatures(function (keys) {
            setFeatures(keys);
        });
    }, []);
    React.useEffect(function () {
        var fetchModel = function () {
            if (DoesModelNeedToBeLoaded(apiModelLoadStatus)) {
                setapiModelLoadStatus(function (previousStatus) { return (__assign(__assign({}, previousStatus), { modelLoading: true })); });
                (function () { return __awaiter(void 0, void 0, void 0, function () {
                    var apiState;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, RadService.getClassSummary(filters.subjectId, filters.startDate, filters.endDate, filters.timeFrameId)];
                            case 1:
                                apiState = _a.sent();
                                if (IsModelLoaded(apiState)) {
                                    setModel(new ClassSummaryLocalModel(apiState.response));
                                    setapiModelLoadStatus(function (previousStatus) { return (__assign(__assign({}, previousStatus), { modelLoading: false, modelLoaded: true })); });
                                }
                                else {
                                    setapiModelLoadStatus(function (previousStatus) { return (__assign(__assign({}, previousStatus), { modelLoading: false, modelLoaded: true, apiFailure: true })); });
                                }
                                return [2 /*return*/];
                        }
                    });
                }); })();
            }
        };
        fetchModel();
    }, [filters, apiModelLoadStatus]);
    var tryAgain = function () {
        setapiModelLoadStatus(function (previousStatus) { return (__assign(__assign({}, previousStatus), { modelLoading: false, modelLoaded: false, apiFailure: false })); });
    };
    var showTestData = function () {
        setDataClicks(dataClicks + 1);
        if (dataClicks > 3) {
            setModel(new ClassSummaryLocalModel(testModelData));
            setapiModelLoadStatus(function (previousStatus) { return (__assign(__assign({}, previousStatus), { modelLoaded: true, apiFailure: false })); });
        }
    };
    if (apiModelLoadStatus.apiFailure) {
        return (React.createElement(UxModal, { showModal: true, showClose: false, handleClose: tryAgain, className: "csd-ux-modal", title: "", size: UxSizeExtraEnum.FULL, body: React.createElement(UxError, { primaryMsg: React.createElement("div", null,
                    "There was an error loading the class summary",
                    " ",
                    React.createElement("span", { role: "none", onClick: showTestData }, "data")), secondaryMsg: "Please try reloading the page. If the problem continues, please contact support", buttonText: "Reload Page", handleClick: tryAgain }) }));
    }
    if (apiModelLoadStatus.modelLoading) {
        return React.createElement(UxLoadingScreen, null);
    }
    if (!model) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(ClassSummaryContainer, { model: model, onSubjectChanged: function (id) { return handleSubjectChange(id); }, onTimeFrameChanged: handleTimeFrameChange, studentClickCallback: function (studentId, studentName) {
            props.studentClickCallback(studentId, GetActiveSubjectId(model), studentName, model.getCourse().id);
        }, lessonResourcesCallback: props.lessonResorcesCallback, viewLessonCallback: props.viewLessonCallback, features: features }));
};
export default ClassSummaryConnect;
