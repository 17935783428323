import * as React from "react";
import { LessonStatus } from "../model/class-summary-local-model";
import { gradeToInt } from "../common/csd-utils";
import { msToHHMMSS } from "../common/datetime-utils";
var LessonStatusText;
(function (LessonStatusText) {
    LessonStatusText[LessonStatusText["Not Passed"] = 2] = "Not Passed";
    LessonStatusText[LessonStatusText["In Progress"] = 3] = "In Progress";
    LessonStatusText[LessonStatusText["Passed"] = 1] = "Passed";
})(LessonStatusText || (LessonStatusText = {}));
var priority = ["Not Passed", "Passed", "In Progress"];
var StudentLessonDetails = function (props) {
    props.studentlessondetails.sort(function (a, b) {
        var gradeLevelA = a.gradeLevel;
        var gradeLevelB = b.gradeLevel;
        var statusA = LessonStatusText[a.status];
        var statusB = LessonStatusText[b.status];
        if (a.status !== b.status) {
            return priority.indexOf(statusA) - priority.indexOf(statusB);
        }
        else if (gradeToInt(gradeLevelA) !== gradeToInt(gradeLevelB)) {
            return gradeToInt(gradeLevelA) - gradeToInt(gradeLevelB);
        }
        else if (gradeLevelA === gradeLevelB &&
            b.score !== a.score &&
            a.status === b.status &&
            a.status !== LessonStatus.InProgress) {
            return a.score - b.score;
        }
        else {
            return 0;
        }
    });
    var formatLessonScore = function (lesson) {
        if (lesson.status === LessonStatus.InProgress) {
            return "-";
        }
        else if (lesson.score === null) {
            return "ERROR";
        }
        else
            return lesson.score + "%";
    };
    var rows = props.studentlessondetails.map(function (lesson) {
        var lessonStatus = lesson.status;
        return (React.createElement("div", { key: lesson.lessonId },
            React.createElement("div", { className: "drill-down-data-stfo " + (lesson.status === LessonStatus.InProgress ? "in-progress-style" : "") },
                React.createElement("div", { className: "lessons-drill-down-status" }, LessonStatusText[lessonStatus]),
                props.lessons.map(function (lessonnames) {
                    return lessonnames.id === lesson.lessonId ? (React.createElement("div", { key: lesson.lessonId, className: "lessons-drill-down-domain" }, lessonnames.domain)) : null;
                }),
                props.lessons.map(function (lessonnames) {
                    var _a;
                    return lessonnames.id === lesson.lessonId ? (React.createElement("div", { key: lesson.lessonId, className: "lessons-drill-down-name" },
                        lessonnames.name,
                        " ",
                        ((_a = lesson.instructionLanguage) === null || _a === void 0 ? void 0 : _a.toLowerCase()) == "spanish" && (React.createElement("p", { className: "student-row-spanish-indicator" }, "SPA")))) : null;
                }),
                React.createElement("div", { className: "just-align-float-center-basis-100" }, lesson.gradeLevel),
                React.createElement("div", { className: "just-align-float-center-basis-100" }, formatLessonScore(lesson)),
                React.createElement("div", { className: "just-align-float-center-basis-100" }, msToHHMMSS(lesson.activeTimeMs))),
            " "));
    });
    return (React.createElement("div", null,
        React.createElement("div", { className: "drill-down-heading-stfo" },
            React.createElement("div", { className: "lessons-drill-down-status" }, "Status"),
            React.createElement("div", { className: "lessons-drill-down-domain" }, "Domain"),
            React.createElement("div", { className: "lessons-drill-down-name" }, "Lesson Title"),
            React.createElement("div", { className: "just-align-float-center-basis-100" }, "Grade Level"),
            React.createElement("div", { className: "just-align-float-center-basis-100" }, "Score"),
            React.createElement("div", { className: "just-align-float-center-basis-100" }, "Active Time")),
        " ",
        React.createElement("div", { className: "student-drill-down-style" }, rows)));
};
export default StudentLessonDetails;
