var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SortState } from "./types";
var getNextSortState = function (value, state) {
    var prevSortState = state.sortColumn === value ? state.sortState : SortState.NoSort;
    switch (prevSortState) {
        case SortState.NoSort:
            return __assign(__assign({}, state), { sortColumn: value, sortState: SortState.Ascending });
        case SortState.Ascending:
            return __assign(__assign({}, state), { sortColumn: value, sortState: SortState.Descending });
        default:
            return __assign(__assign({}, state), { sortColumn: value, sortState: SortState.NoSort });
    }
};
export default getNextSortState;
