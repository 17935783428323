import * as React from "react";
import { LessonStatus } from "../model/class-summary-local-model";
import { gradeToInt } from "../common/csd-utils";
import LessonsMenu from "../common/lessons-menu";
var Group;
(function (Group) {
    Group[Group["FailedMore"] = 0] = "FailedMore";
    Group[Group["FailedOne"] = 1] = "FailedOne";
    Group[Group["Inactive"] = 2] = "Inactive";
    Group[Group["AllPassed"] = 3] = "AllPassed";
})(Group || (Group = {}));
var SfoStudents = function (props) {
    var getGroupForStudent = function (studentId) {
        var stats = props.statsByStudent[studentId];
        if (stats) {
            if (stats.failed) {
                return stats.failed > 1 ? Group.FailedMore : Group.FailedOne;
            }
            if (stats.passed) {
                return Group.AllPassed;
            }
            return Group.Inactive;
        }
        return 0;
    };
    var getFailedLessons = function (studentId) {
        var stats = props.statsByStudent[studentId];
        return stats.failed;
    };
    var getPassedLessons = function (studentId) {
        var stats = props.statsByStudent[studentId];
        return stats.passed;
    };
    var getLessons = function (studentId) {
        if (props.group === Group.AllPassed) {
            return getPassedLessons(studentId);
        }
        if (props.group === Group.FailedMore) {
            return getFailedLessons(studentId);
        }
        if (props.group === Group.FailedOne) {
            return getFailedLessons(studentId);
        }
        return 0;
    };
    var getGroupStats = function () {
        switch (props.group) {
            case Group.AllPassed:
                return props.stats.allPassed;
            case Group.FailedMore:
                return props.stats.failedMore;
            case Group.FailedOne:
                return props.stats.failedOne;
            case Group.Inactive:
                return props.stats.inactive;
            default:
                return 0;
        }
    };
    var getFailedLessonIds = function (student) {
        return student.lessonDetails
            .filter(function (ld) { return ld.status == LessonStatus.Failed; })
            .map(function (ld) { return ld.lessonId; });
    };
    var students = props.students.filter(function (student) { return getGroupForStudent(student.id) === props.group; });
    students.sort(function (a, b) {
        if (getLessons(a.id) !== getLessons(b.id)) {
            return getLessons(b.id) - getLessons(a.id);
        }
        if (a.ilpGrade !== b.ilpGrade) {
            return gradeToInt(a.ilpGrade) - gradeToInt(b.ilpGrade);
        }
        if (a.lastName !== b.lastName) {
            return a.lastName.localeCompare(b.lastName);
        }
        if (a.lastName === b.lastName) {
            return a.firstName.localeCompare(b.firstName);
        }
        return 0;
    });
    var rows = students.map(function (student) {
        var fullName = student.firstName + " " + student.lastName;
        var lessonCount = getLessons(student.id);
        return (React.createElement("div", { className: "student-row", key: student.id },
            React.createElement("div", { className: "student-name" },
                React.createElement("button", { type: "button", className: "name-color clickable", onClick: function () { return props.studentClickCallback(student.id, fullName); } }, fullName),
                React.createElement("div", { className: "lesson-color" },
                    lessonCount,
                    " ",
                    lessonCount === 1 ? "Lesson" : "Lessons"),
                lessonCount >= 1 && (React.createElement(LessonsMenu, { lessonIds: getFailedLessonIds(student), onLessonChange: function (value) {
                        // do nothing
                    }, lessonResourcesMenuCallback: props.studentMenuCallback, viewLessonCallback: props.viewLessonCallback, features: props.features, studentId: student.id })))));
    });
    return (React.createElement("div", { className: "student-table" }, getGroupStats() > 0 ? rows : React.createElement("span", { className: "no-students-font" }, "No students meet this selection")));
};
export default SfoStudents;
