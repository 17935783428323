import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./class-summary.scss";
import "./class-summary-hacks.css";
import StudentsToFocusOn from "./studentstofocuson/students-to-focuson";
import ClassSummaryHeader from "./header/class-summary-header";
import LessonsToFocusOn from "./lessonstofocuson/lessons-to-focus-on";
import Usage from "./usage/usage";
var ClassSummaryContainer = function (props) {
    var _a, _b, _c, _d;
    var subject = ((_a = props.model.getActiveSubject()) === null || _a === void 0 ? void 0 : _a.name) || "ERROR: no active subject!";
    var timeFrame = ((_b = props.model.getActiveTimeFrame()) === null || _b === void 0 ? void 0 : _b.name) || "ERROR: no active subject!";
    var className = props.model.getCourse().name;
    var state = props.model.getCourse().state;
    var getGradeLevel = function (gradeLevel) {
        switch (gradeLevel.toLocaleLowerCase()) {
            case "k":
                return 0;
            default:
                return Number(gradeLevel);
        }
    };
    var lessonResorcesMenuCallback = function (studentId, lessonIds) {
        var filteredLessons = props.model
            .getStudent(studentId)
            .lessonDetails.filter(function (lesson) { return lessonIds.includes(lesson.lessonId); });
        var lessons = filteredLessons.map(function (lesson) {
            return { pathId: Number(lesson.lessonId), gradeLevel: getGradeLevel(lesson.gradeLevel) };
        });
        props.lessonResourcesCallback(lessons, state, subject);
    };
    return (React.createElement("div", { className: "csd-container" },
        React.createElement(ClassSummaryHeader, { course: props.model.getCourse(), subjects: props.model.getSubjects(), activeSubjectId: ((_c = props.model.getActiveSubject()) === null || _c === void 0 ? void 0 : _c.id) || "unknown", timeFrames: props.model.getTimeFrames(), activeTimeFrameId: ((_d = props.model.getActiveTimeFrame()) === null || _d === void 0 ? void 0 : _d.id) || 0, numStudents: props.model.getNumStudents(), onSubjectChange: props.onSubjectChanged, onTimeFrameChange: props.onTimeFrameChanged }),
        React.createElement("div", { className: "csd-columns" },
            React.createElement(StudentsToFocusOn, { students: props.model.getAllStudents(), stats: props.model.getSfoStats(), title: "Students To Focus On", subject: subject, timeFrame: timeFrame, className: className, statsByStudent: props.model.getStudentStatsByStudent(), lessons: props.model.getAllLessons(), getLesson: function (id) { return props.model.getLesson(id); }, studentClickCallback: props.studentClickCallback, studentMenuCallback: lessonResorcesMenuCallback, viewLessonCallback: props.viewLessonCallback, features: props.features }),
            React.createElement(LessonsToFocusOn, { title: "Lessons To Focus On", model: props.model, subject: subject, timeFrame: timeFrame, className: className, studentClickCallback: props.studentClickCallback, lesonMenuCallback: lessonResorcesMenuCallback, viewLessonCallback: props.viewLessonCallback, features: props.features }),
            React.createElement(Usage, { title: "Usage", model: props.model, subject: subject, timeFrame: timeFrame, className: className, studentClickCallback: props.studentClickCallback }))));
};
export default ClassSummaryContainer;
