import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import LessonsMenu from "../common/lessons-menu";
var LFOLessons = function (_a) {
    var lessonsNumFailed = _a.lessons, students = _a.students, activeSubject = _a.activeSubject, onLessonChange = _a.onLessonChange, selectedLesson = _a.selectedLesson, lessonMenuCallback = _a.lessonMenuCallback, viewLessonCallback = _a.viewLessonCallback, features = _a.features;
    var failedLessons = lessonsNumFailed.filter(function (lesson) { return lesson.numFailed > 0; }).splice(0, 3);
    var defaultSelectedLesson = failedLessons.length > 0 && selectedLesson === "" ? failedLessons[0].lesson.id : selectedLesson;
    var getClassNamesForRowButton = function (failedLesson) {
        return "lesson-row clickable" + (defaultSelectedLesson === failedLesson.lesson.id ? " selected-lesson-row" : "");
    };
    var showEmptyLessons = function () {
        return failedLessons.length === 0 && lessonsNumFailed.length !== 0 ? (React.createElement("div", { className: "lfo-aux-text" }, " Your class has passed all of their lessons.")) : (React.createElement("div", { className: "lfo-aux-text" }, "Your class has not completed any lessons within this time frame."));
    };
    var listItems = failedLessons.length > 0
        ? failedLessons.map(function (failedLesson) { return (React.createElement("div", { className: "lesson-flex-container", key: failedLesson.lesson.id },
            React.createElement("button", { className: getClassNamesForRowButton(failedLesson), onClick: function () { return onLessonChange(failedLesson.lesson.id); }, type: "button" },
                React.createElement("div", { className: "lesson-name" }, failedLesson.lesson.name),
                React.createElement("div", { className: "lesson-count-button" }, failedLesson.numFailed)),
            React.createElement(LessonsMenu, { lessonIds: [failedLesson.lesson.id], studentId: students[0].id, subject: activeSubject, onLessonChange: onLessonChange, lessonResourcesMenuCallback: lessonMenuCallback, viewLessonCallback: viewLessonCallback, features: features }))); })
        : // ToDo This logic has an issue as the data doesnot have Inprogress Lessons.Need to figure
            // out a way to get it here
            showEmptyLessons();
    return React.createElement("div", { className: "lesson-table" }, listItems);
};
export default LFOLessons;
