var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable no-console */
import * as Sentry from "@sentry/browser";
import { UxDirectionEnum, UxNavbar, UxOrientationEnum, UxPositionEnum, UxShadesEnum, UxThemesEnum } from "@wne/ux-component-library";
import React, { useState } from "react";
import Actions from "../actions/actions";
import Button from "../button/button";
import { ButtonTypeEnum } from "../button/button.props";
import Pagination from "../pagination/pagination";
import TextToSpeechToolbar from "../text-to-speech-toolbar/text-to-speech-toolbar";
window.ContentReader = window.ContentReader || {};
function hasIncompleteRequiredComponents(components) {
    return components.some(function (comp) { return comp.completionRequired && !comp.state.completed; });
}
var Footer = function (props) {
    var isMasteryCheck = props.isMasteryCheck, isRetryVisible = props.isRetryVisible, showTextToSpeech = props.showTextToSpeech, theme = props.theme, debugMode = props.debugMode, previewMode = props.previewMode, segmentStateChangeCallback = props.segmentStateChangeCallback, takeSubmitCompleteCallback = props.takeSubmitCompleteCallback, segmentReadyCallback = props.segmentReadyCallback, sideKickMessageCallback = props.sideKickMessageCallback, showLanguageTranslation = props.showLanguageTranslation, panelChangeCallback = props.panelChangeCallback, calculatorType = props.calculatorType;
    var is612 = theme === UxThemesEnum.STUDENT_6_8 || theme === UxThemesEnum.STUDENT_9_12;
    var buttonType = isMasteryCheck ? ButtonTypeEnum.SUBMIT : ButtonTypeEnum.CONTINUE;
    var _a = useState({
        enabled: false,
        type: isRetryVisible ? ButtonTypeEnum.TRY_AGAIN : buttonType,
        callback: isRetryVisible ? window.ContentReader.closeFeedback : window.ContentReader.goToNextSegment
    }), footerButton = _a[0], SetFooterButton = _a[1];
    var retryButtonState = {
        enabled: false,
        type: ButtonTypeEnum.TRY_AGAIN,
        callback: window.ContentReader.closeFeedback
    };
    var _b = useState(null), currentSegment = _b[0], setCurrentSegment = _b[1];
    var _c = useState({ hintId: "", vocabId: "" }), playerActions = _c[0], setPlayerActions = _c[1];
    var _d = useState({ showToolbar: false, reset: false }), ttsToolbarState = _d[0], setTtsToolbarState = _d[1];
    var _e = useState(null), segmentTitles = _e[0], setSegmentTitles = _e[1];
    var _f = useState({ pathId: "", version: "", title: "" }), currentPath = _f[0], setCurrentPath = _f[1];
    var _g = useState(false), showSubmit = _g[0], setShowSubmit = _g[1];
    var _h = useState(false), hideLoader = _h[0], setHideLoader = _h[1];
    var _j = useState({
        segmentIds: [],
        inProgressSegmentIndex: -1
    }), takeSegmentsState = _j[0], setTakeSegmentsState = _j[1];
    var speechStream = window.SpeechStream;
    var resetLoader = function () {
        setHideLoader(false);
    };
    var updateFooterButton = function (newButtonState) {
        if (newButtonState.enabled !== footerButton.enabled ||
            newButtonState.type !== footerButton.type ||
            newButtonState.callback !== footerButton.callback) {
            if (isRetryVisible) {
                newButtonState = retryButtonState;
                newButtonState.enabled = true;
            }
            SetFooterButton(newButtonState);
        }
    };
    var updateSegmentsState = function (segmentsState) {
        var inProgressSegmentIndex = segmentsState.findIndex(function (segmentState) { return segmentState.totalCompleted !== segmentState.totalRequired; });
        if (inProgressSegmentIndex === -1)
            inProgressSegmentIndex = segmentsState.length - 1;
        var segmentIds = segmentsState.map(function (segmentState) { return segmentState.segmentId; });
        if (takeSegmentsState.inProgressSegmentIndex !== inProgressSegmentIndex) {
            setTakeSegmentsState({ segmentIds: segmentIds, inProgressSegmentIndex: inProgressSegmentIndex });
        }
    };
    // function to check if max score exceeds 1
    var maxScoreCheck = function (questionData, id) {
        if (!questionData.maxScore || questionData.maxScore <= 1)
            return;
        if (previewMode) {
            alert("MyPath requires that items be worth 1 point - Segment ID: " + id + ", Path ID: " + currentPath.pathId);
        }
        Sentry.captureException(new Error("MyPath requires that items be worth 1 point  - Segment ID: " + id + ", Path ID: " + currentPath.pathId), function (scope) {
            scope.clear();
            scope.setTags({
                segmentId: id,
                pathId: currentPath.pathId || "empty"
            });
            return scope;
        });
    };
    var FooterListener = {
        onPathLaunched: function (path) {
            if (debugMode) {
                console.log("footer - path launched");
            }
            if (path) {
                var buttonState = __assign({}, footerButton);
                buttonState.enabled = true;
                updateFooterButton(buttonState);
                setSegmentTitles(path.segments);
                setCurrentPath({ pathId: path.pathId, version: path.pathVersion.toString(), title: path.pathName });
            }
        },
        onSegmentStateChange: function (segment) {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            if (debugMode) {
                console.log("footer - segment state change");
            }
            var isFeedbackAvailable = (_b = (_a = segment.components[0]) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.feedbackCompleted;
            if (!isMasteryCheck && (isFeedbackAvailable || isFeedbackAvailable === false || segment.components.length > 1))
                setHideLoader(true); // hide footer loader
            if (segment) {
                if (typeof sideKickMessageCallback === "function") {
                    sideKickMessageCallback(segment, false);
                    if (isRetryVisible)
                        (_c = document.querySelectorAll(".sidekick-message")[0]) === null || _c === void 0 ? void 0 : _c.classList.add("hide-sidekick");
                }
            }
            if (JSON.stringify(currentSegment) !== JSON.stringify(segment)) {
                if (segmentTitles) {
                    var current = segmentTitles.find(function (x) { return x.id === segment.segmentId; });
                    if (current) {
                        segment.title = current.title;
                    }
                }
                setCurrentSegment(segment);
                if (typeof segmentStateChangeCallback === "function" && !segment.takeSubmitted) {
                    segmentStateChangeCallback(segment);
                }
            }
            var isLastSegment = segment.segmentOrder.position === segment.segmentOrder.total;
            var canComplete = true;
            var buttonState = {
                enabled: true,
                type: isMasteryCheck ? ButtonTypeEnum.SUBMIT : ButtonTypeEnum.CONTINUE,
                callback: window.ContentReader.goToNextSegment
            };
            var questionComps = segment.components.filter(function (comp) { return comp.type === "Question" || comp.type === "QuestionItem"; });
            if (questionComps.length) {
                // check if max score excceds 1
                if (isMasteryCheck)
                    maxScoreCheck(questionComps[0], segment.segmentId);
                var allQuestionCompsComplete = !questionComps.some(function (comp) { return !comp.state.completed; });
                if (!allQuestionCompsComplete) {
                    if (questionComps.length === 1) {
                        buttonState.type = ButtonTypeEnum.SUBMIT;
                        setShowSubmit(false);
                    }
                    else {
                        setShowSubmit(true);
                    }
                    buttonState.callback = function handleActivitySubmitClick() {
                        buttonState.enabled = false;
                        SetFooterButton(buttonState);
                        window.ContentReader.checkAnswers();
                    };
                    canComplete = false;
                    var shouldDisableSubmitButton = questionComps.some(function (comp) {
                        var hasOnlyOneComp = questionComps.length === 1;
                        var hasIncompleteComp = questionComps.length > 1 && !comp.state.completed;
                        var isInvalid = !comp.state.valid;
                        return (hasOnlyOneComp && isInvalid) || hasIncompleteComp;
                    });
                    if (shouldDisableSubmitButton) {
                        buttonState.enabled = false;
                    }
                }
                if (isMasteryCheck && allQuestionCompsComplete) {
                    if (isLastSegment) {
                        canComplete = false;
                        if (!segment.takeSubmitted) {
                            if (debugMode) {
                                console.log("onSegmentStateChange - about to call submitTake() from (isMasteryCheck && allQuestionCompsComplete)");
                            }
                            window.ContentReader.submitTake();
                        }
                    }
                    else {
                        if (debugMode) {
                            console.log("onSegmentStateChange - in Mastery check with questions done but not yet at last segment");
                        }
                        buttonState.enabled = false;
                        SetFooterButton(buttonState);
                        window.ContentReader.goToNextSegment();
                    }
                }
                var hasAttempts = ((_e = (_d = questionComps[0]) === null || _d === void 0 ? void 0 : _d.state) === null || _e === void 0 ? void 0 : _e.attempts) !== 0;
                var feedbackNotCompleted = hasAttempts && !((_g = (_f = questionComps[0]) === null || _f === void 0 ? void 0 : _f.state) === null || _g === void 0 ? void 0 : _g.feedbackCompleted);
                if (!isMasteryCheck && feedbackNotCompleted) {
                    if (debugMode) {
                        console.log("onSegmentStateChange - the feedback segment has not been completed");
                    }
                    buttonState.enabled = false;
                }
            }
            else if (hasIncompleteRequiredComponents(segment.components)) {
                if (debugMode) {
                    console.log("onSegmentStateChange - one or more required components have not been completed");
                }
                buttonState.enabled = false;
                canComplete = false;
            }
            if (canComplete && isLastSegment) {
                buttonState.callback = function handleActivityComplete() {
                    if (!segment.takeSubmitted) {
                        if (debugMode) {
                            console.log("onSegmentStateChange - about to call submitTake() from (canComplete && isLastSegment)");
                        }
                        window.ContentReader.submitTake();
                    }
                };
            }
            // If the button state is still enabled, user most likely answered the question, close sidekick message if it's open
            if (buttonState.enabled) {
                if (typeof sideKickMessageCallback === "function" &&
                    ((_h = document.querySelectorAll(".sidekick-message")) === null || _h === void 0 ? void 0 : _h.length) > 0) {
                    sideKickMessageCallback(segment, true);
                    if (isRetryVisible) {
                        buttonState.type = retryButtonState.type;
                        buttonState.callback = retryButtonState.callback;
                    }
                }
            }
            updateFooterButton(buttonState);
            window.document.dispatchEvent(new Event("keydown")); // for idle time - keep active as long as there is activity in the player
        },
        onSegmentNavigationChange: function (data) {
            if (debugMode) {
                console.log("footer - segment navigation change");
            }
            setPlayerActions({ hintId: "", vocabId: "" });
            if (data && data.panelsInSegment && data.panelsInSegment.length > 0) {
                var vocabId_1 = "";
                var hintId_1 = "";
                data.panelsInSegment.forEach(function (panel) {
                    switch (panel.icon) {
                        case "book":
                            vocabId_1 = panel.id;
                            break;
                        case "question":
                            hintId_1 = panel.id;
                            break;
                        default:
                            break;
                    }
                });
                setPlayerActions({ hintId: hintId_1, vocabId: vocabId_1 });
            }
            if (showTextToSpeech) {
                speechStream === null || speechStream === void 0 ? void 0 : speechStream.speechTools.stop();
                setTtsToolbarState({ showToolbar: false, reset: true });
            }
        },
        onTakeSubmitComplete: function (take) {
            if (debugMode) {
                console.log("footer - take submit complete");
            }
            setHideLoader(true); // mastery check last question scenario
            if (typeof takeSubmitCompleteCallback === "function") {
                if (Object.prototype.hasOwnProperty.call(take, "error")) {
                    if (debugMode) {
                        console.log("footer - take submit complete errored from Doolittle");
                    }
                    return;
                }
                takeSubmitCompleteCallback(take);
            }
        },
        onTakeStateChange: function (take) {
            if (debugMode) {
                console.log("footer - take state change");
            }
            if (take.takeSubmitted && typeof takeSubmitCompleteCallback === "function") {
                takeSubmitCompleteCallback(take);
            }
            else {
                updateSegmentsState(take.totalsBySegment);
            }
        },
        onSegmentReady: function (data) {
            if (debugMode) {
                console.log("footer - segment ready - " + data);
            }
            if (currentSegment) {
                console.log("Path ID:  " + (currentPath.pathId || "empty") + " Segment Title: " + (currentSegment.title || "empty") + " Segment ID: " + (currentSegment.segmentId || "empty"));
            }
            if (showSubmit) {
                var submitDiv = document.getElementsByClassName("wnr-ccquestion-question-submit-container");
                if (submitDiv && submitDiv.length > 0) {
                    for (var i = 0; i < submitDiv.length; i += 1) {
                        var submitEle = submitDiv[i];
                        if (submitEle)
                            submitEle.style.display = "flex";
                    }
                }
            }
            var videoElement = document.querySelector("video.vjs-tech");
            var parentLayout = document.getElementsByClassName("wnr-contentLayout-layout-type-video")[0];
            var feedbackVideo = document.querySelector("wnr-ccquestion-distractor-rationale-title");
            if (videoElement && !parentLayout && !feedbackVideo) {
                var segmentId_1 = (currentSegment === null || currentSegment === void 0 ? void 0 : currentSegment.segmentId) || "";
                Sentry.captureException(new Error("Content authoring missed a Layout:Video for instructional videos. Activity title: " + currentPath.title), function (scope) {
                    scope.clear();
                    scope.setTags({
                        pathId: currentPath.pathId,
                        version: currentPath.version,
                        segmentId: segmentId_1
                    });
                    return scope;
                });
            }
            if (typeof segmentReadyCallback === "function") {
                segmentReadyCallback();
            }
            if (showTextToSpeech) {
                updateDOMforSpeechStream();
            }
            // loader off
            setHideLoader(true);
        }
    };
    window.ContentReader = __assign(__assign({}, window.ContentReader), FooterListener);
    var footerChildren = [
        {
            item: (React.createElement(React.Fragment, null,
                React.createElement(Actions, { calculatorCallback: function () {
                        var newWindow = window.open("about:blank", "_blank");
                        localStorage.setItem("calculatorType", calculatorType || "Scientific");
                        if (newWindow) {
                            var calculatorTemplate = "<html><head><title>Calculator</title><script src=\"https://www.desmos.com/api/v1.6/calculator.js?apiKey=dcb31709b452b1cf9dc26972add0fda6\"></script> <script> setTimeout(() => { const elt = document.getElementById(\"calculator\"); const calculatorType=localStorage.getItem(\"calculatorType\"); const calculatorTypes = [{label: \"Basic\", value: \"Calculator\"}, {label:\"Graphing\", value: \"GraphingCalculator\"}, {label: \"Scientific\", value:\"ScientificCalculator\"}]; const calculatorObject = calculatorTypes.find(type => type.label === calculatorType); if (calculatorType && elt && calculatorObject) { const { Desmos } = window; const calculator = Desmos && Desmos[calculatorObject.value](elt); }}, 1000); </script> </head> <body> <div id=\"calculator\" style=\"width: 80vw; height: 80vh; margin: 0 auto;\"></div> </body> </html>";
                            newWindow.document.write(calculatorTemplate);
                            newWindow.document.close();
                            newWindow.focus();
                        }
                    }, calculatorTooltip: calculatorType ? calculatorType + " Calculator" : "", show: {
                        Vocab: playerActions.vocabId !== "",
                        Hint: playerActions.hintId !== "",
                        TextToSpeech: showTextToSpeech,
                        Calculator: calculatorType && is612
                    }, vocabCallback: function () {
                        window.ContentReader.togglePanel(playerActions.vocabId);
                        if (showLanguageTranslation) {
                            if (typeof panelChangeCallback === "function") {
                                panelChangeCallback();
                            }
                        }
                    }, hintCallback: function () {
                        window.ContentReader.togglePanel(playerActions.hintId);
                        if (showLanguageTranslation) {
                            if (typeof panelChangeCallback === "function") {
                                panelChangeCallback();
                            }
                        }
                    }, textToSpeechCallback: function () {
                        var showToolbar = ttsToolbarState.showToolbar;
                        setTtsToolbarState({ showToolbar: !showToolbar, reset: false });
                    }, ariaLabel: "Text to Speech" }),
                React.createElement(TextToSpeechToolbar, { showToolbar: ttsToolbarState.showToolbar, reset: ttsToolbarState.reset }))),
            direction: UxDirectionEnum.BEGINNING
        },
        {
            item: (React.createElement(Pagination, { count: (currentSegment === null || currentSegment === void 0 ? void 0 : currentSegment.segmentOrder.total) || 0, active: (currentSegment === null || currentSegment === void 0 ? void 0 : currentSegment.segmentOrder.position) || 0, takeSegmentsState: takeSegmentsState, isMasteryCheck: isMasteryCheck || false, goToSegmentCallback: window.ContentReader.goToSegment, previewMode: previewMode })),
            direction: UxDirectionEnum.CENTER
        },
        {
            item: (React.createElement(Button, { enabled: footerButton.enabled, type: footerButton.type, callback: footerButton.callback, theme: theme, hideLoader: hideLoader, resetLoader: resetLoader }))
        }
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(UxNavbar, { color: !is612 ? UxShadesEnum.LIGHT : UxShadesEnum.DARK, variant: is612 ? UxShadesEnum.DARK : UxShadesEnum.LIGHT, orientation: UxOrientationEnum.HORIZONTAL, items: footerChildren, fixed: UxPositionEnum.BOTTOM, addColumnClass: true, className: "" + (is612 ? "student-6-12-footer" : "") })));
};
function updateDOMforSpeechStream() {
    try {
        window.SpeechStream.domControlTools
            .getNewDomControl(document)
            .addIgnoreListQuerySelector(".sr-item-description, .mq-math-mode, [class^=lrn-accessibility-], .sr-only, .test-title-text, .subtitle, .item-count, .timer, .lrn_sort_gripper, .lrn-choicematrix-column-title, .footer");
    }
    catch (error) {
        console.warn(error);
    }
}
export default Footer;
