import * as React from "react";
var UsageStudents = function (props) {
    var rows = props.studentsUsageStat.map(function (sus) {
        var fullName = sus.student.firstName + " " + sus.student.lastName;
        return (React.createElement("div", { className: "student-row", key: sus.student.id },
            React.createElement("div", { className: "student-name" },
                React.createElement("button", { type: "button", className: "name-color clickable", onClick: function () { return props.studentClickCallback(sus.student.id, fullName); } },
                    fullName,
                    " "),
                React.createElement("div", { className: "usage-detail-data usage-student-active-time" },
                    " ",
                    sus.activeTime,
                    " Min "))));
    });
    return React.createElement("div", { className: "student-table" }, rows);
};
export default UsageStudents;
