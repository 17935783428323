export function gradeToInt(ilpGrade) {
    var i = parseInt(ilpGrade);
    if (isNaN(i)) {
        switch (ilpGrade) {
            case "K":
            case "k":
                return 0;
            case "Adult":
            case "adult":
                return 13;
            default:
                return 0;
        }
    }
    else {
        return i;
    }
}
export function getGradeUpperCase(ilpGrade) {
    switch (ilpGrade) {
        case "k":
            return "K";
        case "adult":
            return "Adult";
        default:
            return ilpGrade;
    }
}
export function getGradeSuffix(gradeLevel) {
    var gradeNumber = gradeToInt(gradeLevel);
    if (gradeNumber > 3 && gradeNumber < 13) {
        return "th Grade";
    }
    if (gradeNumber == 1) {
        return "st Grade";
    }
    if (gradeNumber == 2) {
        return "nd Grade";
    }
    if (gradeNumber == 3) {
        return "rd Grade";
    }
    return " Grade";
}
