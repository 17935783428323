var StudentStats = /** @class */ (function () {
    function StudentStats(studentId) {
        this.passed = 0;
        this.failed = 0;
        this.activeTimeMins = 0;
        this.studentId = studentId;
    }
    return StudentStats;
}());
export default StudentStats;
