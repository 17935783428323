import * as React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
var LessonsMenu = function (props) {
    var dropDownItemClick = function () { return props.lessonResourcesMenuCallback(props.studentId, props.lessonIds); };
    var isMenuVisible = Array.isArray(props.lessonIds) && props.lessonIds.length > 0;
    if (!isMenuVisible) {
        return React.createElement(React.Fragment, null);
    }
    var isPrintDisabled = !props.features.includes("RAD-226-lesson-resources-menu");
    // change the selected lesson
    var updateSelectedLesson = function () {
        props.onLessonChange(props.lessonIds[0].toString());
    };
    var viewLessonClick = function () {
        props.viewLessonCallback(Number(props.lessonIds[0]));
    };
    return (React.createElement(Dropdown, null,
        React.createElement(Dropdown.Toggle, { "aria-label": "Lesson menu", className: "student-menu", bsPrefix: "student-menu-bs", childBsPrefix: "student-menu-child", onClickCapture: function () { return updateSelectedLesson(); } },
            React.createElement("div", { className: "slmenu" },
                " ",
                React.createElement(FontAwesomeIcon, { icon: faEllipsisV }))),
        React.createElement(Dropdown.Menu, { alignRight: false, className: "student-menu-open" },
            React.createElement(Dropdown.Item, { disabled: isPrintDisabled, onClick: function () { return dropDownItemClick(); } }, "Lesson Resources"),
            React.createElement(Dropdown.Item, { onClick: function () { return viewLessonClick(); } }, "View Lesson"))));
};
export default LessonsMenu;
