import * as React from "react";
import SubjectSelector from "./subject-selector";
import TimeFrameSelector from "./timeframe-selector";
import NumberOfStudents from "./number-of-students";
var ClassSummaryHeader = function (props) {
    return (React.createElement("div", { className: "csd-header" },
        React.createElement("div", { className: "csd-header-left" },
            React.createElement("h2", { className: "csd-header-course" }, props.course.name),
            React.createElement(NumberOfStudents, { numStudents: props.numStudents })),
        React.createElement(SubjectSelector, { subjects: props.subjects, activeSubjectId: props.activeSubjectId, onSubjectChange: props.onSubjectChange }),
        React.createElement(TimeFrameSelector, { timeFrames: props.timeFrames, activeTimeFrameId: props.activeTimeFrameId, onTimeFrameChange: props.onTimeFrameChange })));
};
export default ClassSummaryHeader;
