import { faArrowAltDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./scroll-button.scss";
var ScrollButton = function () { return (React.createElement("button", { className: "scroll-button", "aria-label": "Scroll", disabled: true, type: "button" },
    React.createElement("div", null,
        React.createElement("div", { className: "scroll-button-text" },
            React.createElement("span", null, "Scroll")),
        React.createElement("div", { className: "scroll-button-icon" },
            React.createElement(FontAwesomeIcon, { className: "circle-icon-new", icon: faArrowAltDown, title: "Scroll" }))))); };
export default ScrollButton;
