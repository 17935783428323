var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import LFOHeader from "./lfo-header";
import ViewAllButton from "../common/view-all-button";
import DetailsModal from "../common/details-modal";
import LessonDetails from "./lfodetails/lesson-details";
import LFOStudents from "./lfo-students";
import { LessonStatus } from "../model/class-summary-local-model";
var LessonsToFocusOn = /** @class */ (function (_super) {
    __extends(LessonsToFocusOn, _super);
    function LessonsToFocusOn(props) {
        var _a, _b, _c;
        var _this = _super.call(this, props) || this;
        _this.showDetailsModal = function () {
            _this.setState({ detailsVisible: true });
        };
        _this.hideDetailsModal = function () {
            _this.setState({ detailsVisible: false });
        };
        _this.handleonLessonChange = function (id) {
            _this.setState({
                selectedLesson: id
            });
        };
        _this.getStudentFailingSelectedLesson = function () {
            var lessonWithStudents = _this.props.model
                .getCompletedLessonsWithStudents()
                .filter(function (l) { return l.lesson.id === _this.state.selectedLesson; })
                .pop();
            var students = lessonWithStudents ? lessonWithStudents.students : [];
            return students
                .filter(function (student) { var _a; return ((_a = student.lessonDetails.find(function (d) { return d.lessonId === _this.state.selectedLesson; })) === null || _a === void 0 ? void 0 : _a.status) === LessonStatus.Failed; })
                .sort(function (a, b) { return a.lastName.localeCompare(b.lastName); });
        };
        _this.ClassDetailsModal = function (visible) {
            if (visible) {
                return (React.createElement(DetailsModal, { title: "Lesson Details", body: React.createElement(LessonDetails, { lessonDetails: _this.props.model.getCompletedLessonsWithStudents(), subject: _this.props.subject, timeFrame: _this.props.timeFrame, className: _this.props.className, getStudentLessonStatus: function (studentid, lessonid) {
                            return _this.props.model.getStudentLessonStatus(studentid, lessonid);
                        } }), onCloseCallback: _this.hideDetailsModal }));
            }
            return React.createElement(React.Fragment, null);
        };
        var defaultLessonId = ((_c = (_b = (_a = _this.props.model.getCompletedLessonsWithStudents()) === null || _a === void 0 ? void 0 : _a.shift()) === null || _b === void 0 ? void 0 : _b.lesson) === null || _c === void 0 ? void 0 : _c.id) || "";
        _this.state = {
            detailsVisible: false,
            selectedLesson: defaultLessonId
        };
        return _this;
    }
    LessonsToFocusOn.prototype.componentDidUpdate = function (perviousProps) {
        var _a, _b, _c;
        if (perviousProps.subject !== this.props.subject || perviousProps.timeFrame !== this.props.timeFrame) {
            var defaultLessonId = ((_c = (_b = (_a = this.props.model.getCompletedLessonsWithStudents()) === null || _a === void 0 ? void 0 : _a.shift()) === null || _b === void 0 ? void 0 : _b.lesson) === null || _c === void 0 ? void 0 : _c.id) || "";
            this.setState({ selectedLesson: defaultLessonId });
        }
    };
    LessonsToFocusOn.prototype.render = function () {
        var _this = this;
        var lessonsWithStudents = this.props.model.getCompletedLessonsWithStudents();
        return (React.createElement("div", { className: "cs-column" },
            React.createElement(LFOHeader, { title: this.props.title, lessons: lessonsWithStudents, students: this.getStudentFailingSelectedLesson(), activeSubject: this.props.subject, onLessonChange: this.handleonLessonChange, selectedLesson: this.state.selectedLesson, lessonMenuCallback: this.props.lesonMenuCallback, viewLessonCallback: this.props.viewLessonCallback, features: this.props.features }),
            React.createElement("div", { className: "table-wrapper" },
                React.createElement(LFOStudents, { students: this.getStudentFailingSelectedLesson(), selectedLesson: this.state.selectedLesson, studentClickCallback: this.props.studentClickCallback }),
                React.createElement(ViewAllButton, { onViewAllButtonClick: function () { return _this.showDetailsModal(); }, buttonText: "VIEW ALL LESSONS", key: "LESSONS", visibility: lessonsWithStudents.length > 0 })),
            React.createElement("div", { id: "classDetailsModalWrapper" }, this.ClassDetailsModal(this.state.detailsVisible))));
    };
    return LessonsToFocusOn;
}(React.Component));
export default LessonsToFocusOn;
