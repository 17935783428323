import * as React from "react";
var LFOStudents = function (props) {
    var rows = props.students.map(function (student) {
        var _a;
        var fullName = student.firstName + " " + student.lastName;
        var instructionLanguage = (_a = student.lessonDetails
            .filter(function (d) { return d.lessonId === props.selectedLesson; })
            .pop()) === null || _a === void 0 ? void 0 : _a.instructionLanguage;
        return (React.createElement("div", { className: "student-row", key: student.id },
            React.createElement("div", { className: "student-name" },
                React.createElement("button", { type: "button", className: "name-color clickable", onClick: function () { return props.studentClickCallback(student.id, fullName); } },
                    fullName,
                    (instructionLanguage === null || instructionLanguage === void 0 ? void 0 : instructionLanguage.toLowerCase()) == "spanish" && React.createElement("p", { className: "student-row-spanish-indicator" }, "SPA")))));
    });
    return React.createElement("div", { className: "student-table" }, rows);
};
export default LFOStudents;
