import * as React from "react";
import { msToMin } from "../../common/datetime-utils";
import { getGradeUpperCase, getGradeSuffix } from "../../common/csd-utils";
var LessonDetailsDrillDown = function (props) {
    var getStudentLessonScore = function (lessonId, student) {
        var studentLessons = student.lessonDetails;
        var lessonDetail = studentLessons.filter(function (l) { return l.lessonId === lessonId; });
        return lessonDetail[0].score;
    };
    props.students.sort(function (a, b) {
        if (getStudentLessonScore(props.lesson.id, a) !== getStudentLessonScore(props.lesson.id, b)) {
            return getStudentLessonScore(props.lesson.id, a) - getStudentLessonScore(props.lesson.id, b);
        }
        else if (getStudentLessonScore(props.lesson.id, a) === getStudentLessonScore(props.lesson.id, b)) {
            return a.lastName.localeCompare(b.lastName);
        }
        else if (a.lastName === b.lastName) {
            return a.firstName.localeCompare(b.firstName);
        }
        else {
            return 0;
        }
    });
    var rows = props.students.map(function (student) {
        var getStudentTotalActiveTime = function () {
            var activeTime = student.lessonDetails.filter(function (l) { return l.lessonId === props.lesson.id; })[0].activeTimeMs;
            return msToMin(activeTime);
        };
        var instructionLanguage = student.lessonDetails.filter(function (l) { return l.lessonId === props.lesson.id; })[0]
            .instructionLanguage;
        return (React.createElement("div", { key: student.id },
            React.createElement("div", { className: "drill-down-data-lfo" },
                React.createElement("div", { className: "lessons-drill-down-student-name" },
                    student.firstName,
                    " ",
                    student.lastName,
                    (instructionLanguage === null || instructionLanguage === void 0 ? void 0 : instructionLanguage.toLowerCase()) == "spanish" && React.createElement("p", { className: "student-row-spanish-indicator" }, "SPA")),
                React.createElement("div", { className: "lessons-drill-down-score" },
                    getStudentLessonScore(props.lesson.id, student),
                    "% "),
                React.createElement("div", { className: "lessons-drill-down-time" },
                    getStudentTotalActiveTime(),
                    " Min"),
                React.createElement("div", { className: "lessons-drill-down-grade-level" },
                    React.createElement("div", { className: "rectangle" },
                        React.createElement("span", { className: "bold-font-weight", title: props.lesson.gradeLevel.toString() }, getGradeUpperCase(props.lesson.gradeLevel) + getGradeSuffix(props.lesson.gradeLevel)),
                        " ")),
                React.createElement("div", { className: "lessons-drill-down-standards" },
                    React.createElement("div", { className: "detail-name-style" }, props.lesson.standardCodes.join(", ")))),
            " "));
    });
    return (React.createElement("div", null,
        React.createElement("div", { className: "drill-down-heading-lfo" },
            React.createElement("div", { className: "lessons-drill-down-student-name" }, "Students"),
            React.createElement("div", { className: "lessons-drill-down-score" }, "Score"),
            React.createElement("div", { className: "lessons-drill-down-time" }, "Active Time"),
            React.createElement("div", { className: "lessons-drill-down-grade-level" }, "Grade Level"),
            React.createElement("div", { className: "lessons-drill-down-standards" }, "Standards")),
        " ",
        React.createElement("div", null, rows)));
};
export default LessonDetailsDrillDown;
