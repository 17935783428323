var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import SfoHeader from "./sfo-header";
import SfoStudents from "./sfo-students";
import ViewAllButton from "../common/view-all-button";
import DetailsModal from "../common/details-modal";
import StudentDetailTable from "./student-detail-table";
var Group;
(function (Group) {
    Group[Group["FailedMore"] = 0] = "FailedMore";
    Group[Group["FailedOne"] = 1] = "FailedOne";
    Group[Group["Inactive"] = 2] = "Inactive";
    Group[Group["AllPassed"] = 3] = "AllPassed";
})(Group || (Group = {}));
var StudentsToFocusOn = /** @class */ (function (_super) {
    __extends(StudentsToFocusOn, _super);
    function StudentsToFocusOn(props) {
        var _this = _super.call(this, props) || this;
        _this.studentDetailsModal = function (visible, subject, timeFrame) {
            if (visible) {
                return (React.createElement(DetailsModal, { title: "Student Details", body: React.createElement(StudentDetailTable, { subject: subject, timeFrame: timeFrame, className: _this.props.className, students: _this.props.students, lessons: _this.props.lessons, statsByStudent: _this.props.statsByStudent, getLesson: _this.props.getLesson }), onCloseCallback: _this.handlePopupClose }));
            }
        };
        _this.handleViewAllButtonClick = function () {
            _this.setState({ showStudentDetails: true });
        };
        _this.handleGroup = function () {
            if (_this.props.stats.failedMore !== 0) {
                return Group.FailedMore;
            }
            if (_this.props.stats.failedMore === 0 && _this.props.stats.failedOne != 0) {
                return Group.FailedOne;
            }
            if (_this.props.stats.failedMore === 0 && _this.props.stats.failedOne === 0 && _this.props.stats.inactive !== 0) {
                return Group.Inactive;
            }
            if (_this.props.stats.failedMore === 0 &&
                _this.props.stats.failedOne === 0 &&
                _this.props.stats.inactive === 0 &&
                _this.props.stats.allPassed !== 0) {
                return Group.AllPassed;
            }
            return Group.FailedMore;
        };
        _this.handlePopupClose = function () {
            _this.setState({ showStudentDetails: false });
        };
        _this.setGroup = function (group) {
            _this.graphClick = true;
            _this.setState({ group: group });
        };
        _this.state = {
            group: _this.handleGroup(),
            showStudentDetails: false,
            stats: _this.props.stats
        };
        _this.graphClick = false;
        return _this;
    }
    StudentsToFocusOn.prototype.componentDidUpdate = function () {
        if (this.props.stats !== this.state.stats) {
            this.graphClick = false;
        }
    };
    StudentsToFocusOn.prototype.render = function () {
        return (React.createElement("div", { className: "cs-column" },
            React.createElement(SfoHeader, { stats: this.props.stats, selectedGroup: this.state.group, setGroup: this.setGroup, title: this.props.title }),
            React.createElement("div", { className: "table-wrapper" },
                React.createElement(SfoStudents, { stats: this.props.stats, students: this.props.students, group: this.graphClick ? this.state.group : this.handleGroup(), statsByStudent: this.props.statsByStudent, studentClickCallback: this.props.studentClickCallback, studentMenuCallback: this.props.studentMenuCallback, viewLessonCallback: this.props.viewLessonCallback, features: this.props.features }),
                React.createElement(ViewAllButton, { onViewAllButtonClick: this.handleViewAllButtonClick, buttonText: "VIEW ALL STUDENTS", key: "STUDENTS", visibility: this.props.students.length > 0 && this.props.lessons.length > 0 })),
            React.createElement("div", { id: "studentDetailsModalwrapper" }, this.studentDetailsModal(this.state.showStudentDetails, this.props.subject, this.props.timeFrame))));
    };
    return StudentsToFocusOn;
}(React.Component));
export default StudentsToFocusOn;
