/* eslint-disable no-console */
import * as Sentry from "@sentry/browser";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { shouldAutoReadContent } from "../../helpers/content-reader-helper";
import ScrollButton from "../scroll-button/scroll-button";
import SubHeader from "../sub-header/sub-header";
import "./content-reader.scss";
window.ContentReader = window.ContentReader || {};
var DictionaryEnum;
(function (DictionaryEnum) {
    DictionaryEnum["MWLEARNERS"] = "MWLearners";
    DictionaryEnum["MWELEMENTARY"] = "MWElementary";
    DictionaryEnum["MWINTERMEDIATE"] = "MWIntermediate";
    DictionaryEnum["MWSCHOOL"] = "MWSchool";
    DictionaryEnum["OXFORDELEMENTARYES"] = "OxfordElementaryEs";
})(DictionaryEnum || (DictionaryEnum = {}));
var ContentReader = function (props) {
    var token = props.token, doolittleBaseMediaUrl = props.doolittleBaseMediaUrl, doolittleBaseApiUrl = props.doolittleBaseApiUrl, doolittleVersion = props.doolittleVersion, displayCorrectAnswer = props.displayCorrectAnswer, debug = props.debug, disableFastForward = props.disableFastForward, theme = props.theme, isSpanishMathContent = props.isSpanishMathContent, _a = props.disableAnnotations, disableAnnotations = _a === void 0 ? true : _a, _b = props.shouldAutoPlay, shouldAutoPlay = _b === void 0 ? true : _b;
    var _c = useState({ loaded: false, error: false }), scriptState = _c[0], setScriptState = _c[1];
    var _d = useState({ loaded: false, error: false }), ssScriptState = _d[0], setSsScriptState = _d[1];
    var _e = useState({ display: false, subtitle: "", icon: "" }), navSubHead = _e[0], setNavSubHead = _e[1];
    var scrollIndicatorRef = useRef(null);
    var contentReaderRef = useRef(null);
    var apiUrl = doolittleBaseApiUrl + "/api/gw";
    var uri = apiUrl + "/graphql";
    var debugMode = !!debug;
    var noFastForward = !!disableFastForward;
    var uxTheme = "student-" + (theme || "3-5");
    var contentReaderWrapperTag = "content-reader-wrapper";
    var contentReaderTag = "content-reader";
    var navSubActiveClass = navSubHead.display ? "nav-active" : "";
    var richTextType = disableAnnotations ? "ModifiedRichText" : "RichText";
    var minimumVideoQualityDefault = 270;
    var minimumVideoQualityOverride = sessionStorage.getItem("minimumVideoQuality");
    var minimumVideoQuality = minimumVideoQualityOverride === null || Number.isNaN(Number(minimumVideoQualityOverride))
        ? minimumVideoQualityDefault
        : Number(minimumVideoQualityOverride);
    var shouldAutoRead = shouldAutoReadContent(theme);
    var dictionaryType = DictionaryEnum.MWLEARNERS;
    if (theme === "6-8") {
        dictionaryType = DictionaryEnum.MWINTERMEDIATE;
    }
    else if (theme === "9-12") {
        dictionaryType = DictionaryEnum.MWSCHOOL;
    }
    var showHideScrollIndicator = function () {
        var playerDiv = document.getElementById(contentReaderWrapperTag);
        var scrollIndicatorDiv = scrollIndicatorRef.current;
        if (playerDiv === null) {
            return false;
        }
        if (scrollIndicatorDiv) {
            setTimeout(function () {
                scrollIndicatorDiv.style.display = playerDiv.scrollHeight > playerDiv.clientHeight ? "block" : "none";
            }, 100);
        }
        return true;
    };
    var isFunction = function (funct) { return typeof funct === "function"; };
    var renderSubHead = function () {
        var _a, _b, _c;
        try {
            var isContentLayout = document.querySelector(".wnr-contentLayout-layout") !== null;
            if (isContentLayout) {
                var withIcon = document.querySelector(".wnr-contentLayout-layout-type-subhead_with_icon");
                var withoutIcon = document.querySelector(".wnr-contentLayout-layout-type-subhead_without_icon");
                var isSubheadWithOrWithoutIconContentLayout = withIcon || withoutIcon;
                var subtitle = void 0;
                var icon = void 0;
                if (isSubheadWithOrWithoutIconContentLayout !== null) {
                    var subheadContainer = isSubheadWithOrWithoutIconContentLayout.querySelector(".wnr-contentLayout-empty-bar + div");
                    if (subheadContainer !== null) {
                        if (withIcon) {
                            subtitle = (_a = subheadContainer.querySelector("h3 span span")) === null || _a === void 0 ? void 0 : _a.textContent;
                            icon = (_b = subheadContainer.querySelector(".wnr-ccimg-image-container img")) === null || _b === void 0 ? void 0 : _b.getAttribute("src");
                            if (!icon) {
                                throw new Error("SubHeadLayout - Image not found - " + apiUrl);
                            }
                        }
                        if (withoutIcon) {
                            subtitle = (_c = subheadContainer.querySelector(".wnr-cctext-richText-richtext-editor span span")) === null || _c === void 0 ? void 0 : _c.textContent;
                        }
                        if (!subtitle) {
                            throw new Error("SubHeadLayout - Subtitle not found - " + apiUrl);
                        }
                        setNavSubHead({ display: true, subtitle: subtitle || "", icon: icon || "" });
                    }
                    else {
                        throw new Error("SubHeadLayout - Layout format incompatible - " + apiUrl);
                    }
                }
            }
        }
        catch (err) {
            console.error(err.message);
        }
    };
    var renderDynamicWidth = function () {
        try {
            // the transcripts section parent node
            var targetNode = document.getElementsByClassName("wnr-ccmedia-transcript-transcript-form")[0];
            var parentLayout = document.getElementsByClassName("wnr-contentLayout-layout-type-video")[0];
            if (targetNode !== undefined && parentLayout !== undefined) {
                observer.observe(targetNode, config);
            }
        }
        catch (e) {
            console.error(e.message);
        }
    };
    // function to increase the container width if the video leaded is small
    var renderContainerWidth = function () {
        try {
            var videoElement_1 = document.getElementsByClassName("vjs-tech")[0];
            var videoContainer_1 = document.getElementsByClassName("wnr-contentLayout-layout-type-video")[0];
            var videoContainerLoaded_1 = document.querySelector(".wnr-ccmedia-media-video");
            var adjustSizes_1 = function () {
                if (videoContainer_1 !== null &&
                    videoElement_1.getAttribute("src") !== null &&
                    !videoContainer_1.classList.contains("video-container-resize") &&
                    !videoContainer_1.classList.contains("video-with-transcripts")) {
                    videoContainer_1.classList.remove("video-container-resize");
                    var vh = window.innerHeight;
                    var vw = window.innerWidth;
                    if (vw > vh) {
                        var videoHeight = videoElement_1.getBoundingClientRect().height;
                        var svRatio = videoHeight / vh; // video to screen ratio
                        if ((vh > 768 && svRatio < 0.75) || (vh <= 768 && svRatio < 0.74)) {
                            videoContainer_1.classList.add("video-container-resize");
                        }
                    }
                }
            };
            if (videoElement_1 && videoContainerLoaded_1) {
                videoElement_1.addEventListener('canplay', function () {
                    adjustSizes_1();
                    videoContainerLoaded_1.classList.add('loaded');
                    videoElement_1.removeEventListener('canplay', function () { });
                });
            }
        }
        catch (e) {
            console.error(e.message);
        }
    };
    var launchReader = useCallback(function () {
        var launchSettings = {
            contentComponents: {
                Question: {
                    revealAnswerCorrectness: !!displayCorrectAnswer
                }
            }
        };
        var labelBundles = !isSpanishMathContent
            ? null
            : [{ id: "spanish-custom-labels", language: "es", labels: { credits: { general: "Créditos!!!" } } }];
        // @ts-ignore
        if (typeof WNReader !== "undefined") {
            if (debugMode) {
                console.log("WNReader called: ", document.getElementById(contentReaderTag));
                console.log("has auto Read", shouldAutoRead);
            }
            var reader_1 = window.WNReader("#" + contentReaderTag, {
                debug: debugMode,
                featureFlags: {
                    apiPath: "https://content.app.edgenuity.com/api/flags/api/v1"
                },
                numbering: true,
                showHeader: false,
                takeEventsEnabled: true,
                tailpipeUrl: apiUrl + "/events",
                display: {
                    pathLabel: "Activity",
                    segmentLabel: "Page"
                },
                progressFooter: {
                    show: false,
                    btnLabel: "Continue",
                    disableDefaultAction: false
                },
                navigation: {
                    pageIndexArrows: false,
                    arrows: false
                },
                annotations: {
                    disabled: disableAnnotations,
                    readonly: true,
                    hideSidebarWhenEmpty: true
                },
                panels: {
                    disabled: false,
                    contentOptions: ["RichText", "Image", "Video", "Audio", "Instruction"]
                },
                passages: {
                    disabled: false
                },
                labeling: {
                    aliasDisplay: "Alias",
                    aliasOptions: ["Production", "QA"]
                },
                labelBundles: labelBundles,
                tagging: {
                    types: ["word-count", "lexile"]
                },
                contentOptions: [richTextType, "Image", "Video", "Audio", "Question", "Instruction", "LinkedLearningPath"],
                contentComponents: {
                    Image: {
                        apiPath: apiUrl,
                        enableClickEvent: true
                    },
                    Question: {
                        apiPath: apiUrl,
                        completionRequired: true,
                        hideSubmit: false,
                        answerCorrectnessCues: {
                            audioPositivePath: doolittleBaseMediaUrl + "/static/assets/correct.mp3",
                            audioNegativePath: doolittleBaseMediaUrl + "/static/assets/incorrect.mp3",
                            showIconDuration: 1600,
                            iconsEnabled: true
                        },
                        feedback: {
                            display: "spotlight",
                            required: true,
                            disableFastForward: noFastForward,
                            autoPlay: true,
                            minimumQuality: minimumVideoQuality,
                            type: {
                                Audio: {
                                    display: "below"
                                }
                            }
                        },
                        readAloud: {
                            on: true,
                            autoRead: shouldAutoRead && shouldAutoPlay,
                            easyClick: {
                                clozeassociation: {
                                    selectors: [".lrn_draggable"]
                                },
                                mcq: {
                                    selectors: [".lrn-mcq-option .lrn-label"]
                                },
                                imageclozeassociationV2: {
                                    selectors: [".lrn_draggable"]
                                },
                                classification: {
                                    selectors: [".lrn_draggable"]
                                }
                            }
                        }
                    },
                    QuestionItem: {
                        completionRequired: true,
                        feedback: {
                            display: "spotlight",
                            required: true,
                            disableFastForward: noFastForward,
                            autoPlay: true,
                            minimumQuality: minimumVideoQuality,
                            type: {
                                Audio: {
                                    display: "below"
                                }
                            }
                        },
                        readAloud: {
                            on: true,
                            autoRead: shouldAutoRead && shouldAutoPlay,
                            easyClick: {
                                clozeassociation: {
                                    selectors: [".lrn_draggable"]
                                },
                                mcq: {
                                    selectors: [".lrn-mcq-option .lrn-label"]
                                },
                                imageclozeassociationV2: {
                                    selectors: [".lrn_draggable"]
                                },
                                classification: {
                                    selectors: [".lrn_draggable"]
                                }
                            }
                        }
                    },
                    Video: {
                        apiPath: apiUrl,
                        autoPlay: shouldAutoPlay,
                        completionRequired: true,
                        disableFastForward: noFastForward,
                        showTranscriptOnRight: true,
                        minimumQuality: minimumVideoQuality
                    },
                    Audio: {
                        apiPath: apiUrl,
                        // In the MPNG Educator app, "completionRequired" must always evaluate to false.
                        // In the MPNG Content Preview app, "completionRequired" must always evaluate to false.
                        // In the MPNG Student app, we are adding this flag in order to:
                        // - in Prod set "completionRequired" to false in order to prevent some students getting stuck (NXTGN-3155).
                        // - in non-Prod set "completionRequired" to true in order to enable fix https://wn-doolittle.atlassian.net/browse/DT-318
                        // This flag will be removed by NXTGN-3227 after DT-318 is fixed and NXTGN-2255 feature restored
                        completionRequired: sessionStorage.getItem("FF_WNReader_contentComponents_Audio_completionRequired") === "on",
                        autoPlay: shouldAutoRead && shouldAutoPlay
                    },
                    LinkedLearningPath: {
                        apiPath: apiUrl
                    },
                    RichText: {
                        tools: [
                            "BOLD",
                            "ITALIC",
                            "UNDERLINE",
                            "H2",
                            "H3",
                            "H4",
                            "UL",
                            "OL",
                            "INDENT",
                            "UNINDENT",
                            "ALIGN",
                            "LINK",
                            "VOCABULARY",
                            "CLEAR"
                        ]
                    }
                },
                dictionary: {
                    id: dictionaryType,
                    logoUrl: "https://dictionaryapi.com/images/info/branding-guidelines/MWLogo_DarkBG_120x120_2x.png"
                },
                organization: "Imagine Learning"
            });
            reader_1.addListener("launched", function (data) {
                if (debugMode) {
                    console.log("reader - launched");
                    console.log("current theme :", theme);
                }
                if (isFunction(window.ContentReader.onPathLaunched))
                    window.ContentReader.onPathLaunched(data);
            });
            reader_1.addListener("segmentComplete", function (data) {
                if (debugMode)
                    console.log("reader - segment complete");
                if (isFunction(window.ContentReader.onSegmentComplete))
                    window.ContentReader.onSegmentComplete(data);
            });
            reader_1.addListener("segmentStateChange", function (data) {
                var _a;
                if (debugMode)
                    console.log("reader - segment state change");
                // remove close button from feedback videos
                if (document.querySelector(".wnr-ccquestion-distractor-button") &&
                    document.querySelector(".wnr-ccmedia-media-video"))
                    (_a = document.querySelector(".wnr-ccquestion-distractor-button")) === null || _a === void 0 ? void 0 : _a.remove();
                if (isFunction(window.ContentReader.onSegmentStateChange))
                    window.ContentReader.onSegmentStateChange(data);
                renderContainerWidth();
            });
            reader_1.addListener("takeCompletionStateChange", function (data) {
                if (debugMode)
                    console.log("reader - take completion state change");
                if (isFunction(window.ContentReader.onTakeCompletionStateChange))
                    window.ContentReader.onTakeCompletionStateChange(data);
            });
            reader_1.addListener("segmentNavigationChange", function (data) {
                if (debugMode)
                    console.log("reader - segment navigation change");
                if (isFunction(window.ContentReader.onSegmentNavigationChange))
                    window.ContentReader.onSegmentNavigationChange(data);
            });
            reader_1.addListener("takeComplete", function (data) {
                if (debugMode)
                    console.log("reader - take complete");
                if (isFunction(window.ContentReader.onTakeComplete))
                    window.ContentReader.onTakeComplete(data);
            });
            reader_1.addListener("takeSubmitComplete", function (data) {
                if (debugMode)
                    console.log("reader - take submit complete");
                if (isFunction(window.ContentReader.onTakeSubmitComplete))
                    window.ContentReader.onTakeSubmitComplete(data);
            });
            reader_1.addListener("takeStateChange", function (data) {
                if (debugMode)
                    console.log("reader - take state change");
                if (isFunction(window.ContentReader.onTakeStateChange))
                    window.ContentReader.onTakeStateChange(data);
            });
            reader_1.addListener("segmentReady", function (data) {
                if (debugMode)
                    console.log("reader - segment ready");
                renderSubHead();
                // Changing the width of the video screen when transcripts are loaded
                renderDynamicWidth();
                if (isFunction(window.ContentReader.onSegmentReady))
                    window.ContentReader.onSegmentReady(data);
                showHideScrollIndicator();
                renderContainerWidth();
            });
            reader_1.addListener("mediaPlayStateChange", function (data) {
                if (debugMode)
                    console.log("reader - media play state");
                if (isFunction(window.ContentReader.mediaPlayStateChange))
                    window.ContentReader.mediaPlayStateChange(data);
                renderContainerWidth();
            });
            reader_1.launch({
                uri: uri,
                token: token,
                launchSettings: launchSettings
            });
            window.ContentReader.togglePanel = function (panelId) {
                if (debugMode) {
                    console.log("reader - togglePanel");
                }
                reader_1.togglePanel(panelId);
            };
            window.ContentReader.goToNextSegment = function () {
                setNavSubHead({ display: false, subtitle: "", icon: "" });
                if (debugMode) {
                    console.log("reader - goToNextSegment");
                }
                reader_1.goToNextSegment();
            };
            window.ContentReader.goToPrevSegment = function () {
                setNavSubHead({ display: false, subtitle: "", icon: "" });
                if (debugMode) {
                    console.log("reader - goToPrevSegment");
                }
                reader_1.goToPrevSegment();
            };
            window.ContentReader.goToSegment = function (segmentId) {
                setNavSubHead({ display: false, subtitle: "", icon: "" });
                if (debugMode) {
                    console.log("reader - goToSegment, segmentId: " + segmentId);
                }
                reader_1.goToSegment(segmentId);
            };
            window.ContentReader.checkAnswers = function () {
                if (debugMode) {
                    console.log("reader - checkAnswers");
                }
                reader_1.checkAnswers();
            };
            window.ContentReader.submitTake = function () {
                if (debugMode) {
                    console.log("reader - submitTake");
                }
                reader_1.submitTake();
            };
            window.ContentReader.destroy = function () {
                if (debugMode) {
                    console.log("reader - destroy");
                }
                reader_1.destroy();
            };
            window.ContentReader.closeFeedback = function () {
                var _a;
                if (debugMode) {
                    console.log("reader - close feedback");
                }
                (_a = document.querySelectorAll(".sidekick-message")[0]) === null || _a === void 0 ? void 0 : _a.classList.remove("hide-sidekick");
                reader_1.closeFeedback();
            };
        }
    }, [
        uri,
        token,
        debugMode,
        noFastForward,
        apiUrl,
        doolittleBaseMediaUrl,
        displayCorrectAnswer,
        dictionaryType,
        disableAnnotations,
        isSpanishMathContent,
        minimumVideoQuality,
        richTextType,
        shouldAutoRead
    ]);
    window.addEventListener("resize", function () {
        showHideScrollIndicator();
    });
    var modifyLayoutWidth = function () {
        var tSection = document.getElementsByClassName("wnr-ccmedia-transcript-transcript-read-mode")[0];
        var segmentLayout = document.getElementsByClassName("wnr-segment-layout")[0];
        if (tSection !== undefined) {
            // transcripts section is loaded
            if (segmentLayout !== undefined) {
                segmentLayout.classList.add("video-with-transcripts");
            }
        }
        else if (segmentLayout !== undefined) {
            segmentLayout.classList.remove("video-with-transcripts");
        }
    };
    var config = { childList: true };
    // Callback function to execute when mutations on the transcripts are observed
    var observerCallback = function (mutationsList, observer) {
        for (var i = 0, len = mutationsList.length; i < len; i += 1) {
            if (mutationsList[i].type === "childList") {
                modifyLayoutWidth();
            }
        }
    };
    // Create an observer instance linked to the transcripts callback function
    var observer = new MutationObserver(observerCallback);
    // Doolittle load error message
    var doolittleLoadError = useCallback(function () {
        var wrapperDiv = document.getElementById(contentReaderWrapperTag);
        if (!document.getElementById("dolittle-load-error")) {
            var errorMsg = document.createElement("div");
            errorMsg.id = "dolittle-load-error";
            errorMsg.innerHTML = "Oops! Some Resources could not be downloaded. Please contact your IT support";
            if (wrapperDiv)
                wrapperDiv.appendChild(errorMsg);
            Sentry.captureException(new Error("CTK Reader Resources could not be downloaded. Url - " + doolittleBaseMediaUrl + ", User ID " + sessionStorage.getItem("userID") + ", Grade: " + sessionStorage.getItem("gradeLevel")));
        }
    }, [doolittleBaseMediaUrl]);
    useLayoutEffect(function () {
        var link = document.createElement("link");
        link.href = doolittleBaseMediaUrl + "/static/reader/" + doolittleVersion + "/reader." + doolittleVersion + ".css";
        link.rel = "stylesheet";
        link.id = "dolittle-css";
        link.onerror = function () {
            if (debugMode)
                doolittleLoadError();
        };
        document.body.appendChild(link);
        // Speech stream Version 3
        if (document.getElementById("speechStreamv3.js") === null) {
            var ss_script = document.createElement("script");
            ss_script.id = "speechStreamv3.js";
            ss_script.src = "https://toolbar.speechstream.net/SpeechStream/3.7.0/speechstream.js";
            ss_script.async = true;
            ss_script.dataset.speechstreamConfig = "ImagineLearningMPv370R1";
            document.body.appendChild(ss_script);
            ss_script.onload = function () { return setSsScriptState({ loaded: true, error: false }); };
            ss_script.onerror = function () {
                Sentry.captureException(new Error("Error loading text-to-speech script, User ID " + sessionStorage.getItem("userID") + ", Grade: " + sessionStorage.getItem("gradeLevel")));
            };
        }
        else {
            setSsScriptState({ loaded: true, error: false });
        }
        var readerScript = document.getElementById("doolittle-reader.js");
        var doolittleIsReady = "doolittle-reader-ready";
        if (readerScript === null) {
            var script = document.createElement("script");
            script.id = "doolittle-reader.js";
            script.src = doolittleBaseMediaUrl + "/static/reader/" + doolittleVersion + "/reader." + doolittleVersion + ".js";
            script.async = true;
            script.onload = function () {
                var reader = document.getElementById("doolittle-reader.js");
                if (reader) {
                    reader.className = "ready";
                    document.dispatchEvent(new CustomEvent("doolittle-reader-ready"));
                    setScriptState({ loaded: true, error: false });
                }
            };
            script.onerror = function () {
                doolittleLoadError();
            };
            document.body.appendChild(script);
        }
        else if (readerScript.classList.contains("ready")) {
            // When readerScript loading was initiated by edge-lessons preloader
            setScriptState({ loaded: true, error: false });
        }
        else {
            document.addEventListener(doolittleIsReady, function () { return setScriptState({ loaded: true, error: false }); });
        }
        return function () {
            if (debugMode) {
                console.log("reader useLayoutEffect1 cleanup");
            }
            document.body.removeChild(link);
            var node = document.getElementById(contentReaderTag);
            if (node) {
                if (debugMode) {
                    console.log(contentReaderTag + " is being removed");
                }
                node.remove();
                if (typeof window.ContentReader.destroy === "function") {
                    if (debugMode) {
                        console.log("reader destroy called in useLayoutEffect1 cleanup");
                    }
                    window.ContentReader.destroy();
                }
            }
        };
    }, [doolittleBaseMediaUrl, doolittleVersion, debugMode, doolittleLoadError]);
    useLayoutEffect(function () {
        if (scriptState.loaded && document.getElementById(contentReaderTag) !== null) {
            launchReader();
        }
        var wrapperDiv = document.getElementById(contentReaderWrapperTag);
        if (wrapperDiv !== null) {
            var firstScroll_1 = true;
            wrapperDiv.addEventListener("scroll", function () {
                // Prevent autoplay scroll and show top of the screen
                if (firstScroll_1) {
                    firstScroll_1 = false;
                }
                var scrollIndicatorDiv = scrollIndicatorRef.current;
                if (scrollIndicatorDiv) {
                    scrollIndicatorDiv.style.display = "none";
                }
            });
        }
    }, [launchReader, scriptState]);
    useEffect(function () {
        if (ssScriptState.loaded) {
            window.addEventListener("speechToolbarLoaded", function (e) {
                window.SpeechStream = e.detail;
            }, false);
        }
    }, [ssScriptState]);
    var scrollContent = function () {
        if (contentReaderRef.current)
            contentReaderRef.current.scrollTop = contentReaderRef.current.scrollHeight;
        var scrollIndicatorDiv = scrollIndicatorRef.current;
        if (scrollIndicatorDiv) {
            scrollIndicatorDiv.style.display = "none";
        }
    };
    return (React.createElement("div", { id: contentReaderWrapperTag, className: contentReaderWrapperTag + " " + navSubActiveClass, ref: contentReaderRef },
        navSubHead.display && React.createElement(SubHeader, { icon: navSubHead.icon, subtitle: navSubHead.subtitle }),
        React.createElement("div", { id: contentReaderTag, className: contentReaderTag + " " + uxTheme }),
        React.createElement("div", { className: "scroll-indicator bounce", ref: scrollIndicatorRef, onClick: scrollContent, onKeyUp: function () { return null; }, role: "button", tabIndex: 0 },
            React.createElement(ScrollButton, null))));
};
export default ContentReader;
