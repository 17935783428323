/* eslint-disable radix */
// eslint-disable-next-line import/prefer-default-export
export var shouldAutoReadContent = function (theme) {
    if (!theme)
        return true;
    var grade = parseInt(theme.split("-")[0]);
    if (Number.isNaN(grade)) {
        return true;
    }
    return parseInt(theme.split("-")[0]) < 6;
};
