var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ContentReader from "@wne/ctk-reader-wrapper";
import { IlpService, LanguageEnum, ServiceStatusEnum } from "@wne/mpng-api-service";
import { UxIcon, UxIconEnum, UxIconLibraryEnum, UxSizeEnum, UxSizeExtendedEnum } from "@wne/ux-component-library";
import React, { useEffect, useRef, useState } from "react";
import { Carousel, Container, Dropdown, Row } from "react-bootstrap";
import "./educator-content-reader.scss";
window.ContentReader = window.ContentReader || {};
var spinner = (React.createElement(UxIcon, { icon: UxIconEnum.CIRCLE_NOTCH, size: UxSizeExtendedEnum.X2, className: "animation spinner d-flex mx-auto" }));
var EducatorContentReader = function (_a) {
    var _b;
    var doolittleBaseMediaUrl = _a.doolittleBaseMediaUrl, doolittleBaseApiUrl = _a.doolittleBaseApiUrl, activities = _a.activities, selectedIndex = _a.selectedIndex, theme = _a.theme, debugMode = _a.debugMode, lessonAttemptLanguage = _a.lessonAttemptLanguage, spanishAvailable = _a.spanishAvailable, ilpId = _a.ilpId, pathId = _a.pathId, pathVersion = _a.pathVersion, title = _a.title, spanishTitle = _a.spanishTitle, handleGetActivities = _a.handleGetActivities;
    var _c = useState(__assign(__assign({}, activities[selectedIndex || 0]), { index: selectedIndex || 0 })), selectedActivity = _c[0], setSelectedActivity = _c[1];
    var _d = useState(), selectedActivitySegment = _d[0], setSelectedActivitySegment = _d[1];
    var _e = useState({ response: [] }), activitySegments = _e[0], setActivitySegments = _e[1];
    var _f = useState(0), carouselIndex = _f[0], setCarouselIndex = _f[1];
    var _g = useState(14), carouselItemCount = _g[0], setCarouselItemCount = _g[1];
    var _h = useState(null), currentSegment = _h[0], setCurrentSegment = _h[1];
    var _j = useState(null), segmentTitles = _j[0], setSegmentTitles = _j[1];
    var _k = useState(""), currentPathId = _k[0], setPathId = _k[1];
    var _l = useState({
        status: ServiceStatusEnum.LOADING,
        payload: {},
        showPlayer: false
    }), sectionState = _l[0], setSectionState = _l[1];
    var _m = useState(""), selectedLanguage = _m[0], setSelectedLanguage = _m[1];
    var handleLanguageSelect = function (key) {
        var language = key === languages[LanguageEnum.English] ? LanguageEnum.English : LanguageEnum.Spanish;
        setSelectedLanguage(key !== null && key !== void 0 ? key : languages[LanguageEnum.English]);
        handleGetActivities(ilpId, pathId, pathVersion, title, language, spanishTitle);
    };
    var languages = ["English", "Spanish"];
    var carouselDivRef = useRef(null);
    var handleSelect = function (selectedIndex, e) {
        setCarouselIndex(selectedIndex);
    };
    var handleActivitySelect = function (key) {
        setCarouselIndex(0);
        var index = parseInt(key);
        if (index > -1) {
            window.ContentReader.destroy();
            setSelectedActivity(__assign(__assign({}, activities[index]), { index: index }));
        }
    };
    var handleSegmentSelect = function (item) {
        setSelectedActivitySegment(item);
        window.ContentReader.goToSegment(item.segmentID);
    };
    window.addEventListener("resize", function () {
        setCarouselIndex(0);
        handleCarouselItemCalc();
    });
    var handleCarouselItemCalc = function () {
        if (carouselDivRef.current) {
            var carousel = carouselDivRef.current;
            //Subtracting 40 to account for carousel arrows
            var clientWidth = carousel.clientWidth - 40;
            var nmbOfItemsPerSlide = Math.ceil(clientWidth / 86);
            setCarouselItemCount(nmbOfItemsPerSlide);
        }
    };
    useEffect(function () {
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var nextSectionTokenResult, showPlayer, status_1, payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, IlpService.getActivityReviewToken(selectedActivity.pathId, selectedActivity.pathVersion, selectedActivity.takeId || "", selectedActivity.isMasteryCheck, selectedActivity.lessonAttemptId || "")];
                    case 1:
                        nextSectionTokenResult = _a.sent();
                        if (nextSectionTokenResult.status === ServiceStatusEnum.LOADED) {
                            if (nextSectionTokenResult.payload && nextSectionTokenResult.payload.pathID) {
                                showPlayer = true;
                                status_1 = nextSectionTokenResult.status, payload = nextSectionTokenResult.payload;
                                setSectionState({ status: status_1, payload: payload, showPlayer: showPlayer });
                            }
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        if (selectedActivity.pathId) {
            setSectionState({ status: ServiceStatusEnum.LOADING, payload: {}, showPlayer: false });
            fetchData();
        }
    }, [selectedActivity]);
    useEffect(function () {
        var index = selectedActivity.index;
        setSelectedActivity(__assign(__assign({}, activities[index]), { index: index }));
    }, [activities]);
    var renderItems = function (items, startIndex, maxIndex) {
        if (items !== null) {
            if (maxIndex > items.length)
                maxIndex = items.length;
            return items.slice(startIndex, maxIndex).map(function (item, index) {
                var currentSegment = (selectedActivitySegment === null || selectedActivitySegment === void 0 ? void 0 : selectedActivitySegment.segmentID) === item.segmentID;
                var itemWrapperClass = "item-wrapper";
                itemWrapperClass += currentSegment ? " item-backdrop" : "";
                var itemClass = item.completed ? "complete" : "incomplete";
                itemClass += currentSegment ? " active" : "";
                var iconDisplay = item.inProgress || (item.completed && item.correct != null) ? true : false;
                var itemIcon = item.inProgress ? UxIconEnum.MAP_MARKER_ALT : item.correct ? UxIconEnum.CHECK : UxIconEnum.TIMES;
                var itemIconClass = "icon-float ";
                itemIconClass += item.inProgress ? "item-inprogress" : item.correct ? "item-correct" : "item-incorrect";
                return (React.createElement("div", { key: index, className: itemWrapperClass },
                    React.createElement("div", { onClick: function () {
                            handleSegmentSelect(item);
                        }, className: "lesson-viewer-carousel-item font-weight-bold " + itemClass },
                        item.displayOrder,
                        iconDisplay && (React.createElement(UxIcon, { icon: itemIcon, className: itemIconClass + " bg-white shape rounded-circle p-1", library: UxIconLibraryEnum.REGULAR, size: UxSizeEnum.MEDIUM, squared: true })))));
            });
        }
    };
    var renderPanel = function (items, panelIndex) {
        var itemCount = carouselItemCount;
        var startIndex = panelIndex * itemCount - itemCount;
        var maxIndex = panelIndex * itemCount;
        return (React.createElement("div", { key: panelIndex, className: "carousel-item" }, renderItems(items, startIndex, maxIndex)));
    };
    var renderCarousel = function (items) {
        var itemCount = carouselItemCount;
        //Determine how many times to render a carousel panel
        var panels = items != null ? Math.ceil(items.length / itemCount) : 0;
        var renderPanelData = [];
        if (panels !== 0) {
            for (var i = 1; i <= panels; i++) {
                renderPanelData.push(renderPanel(items, i));
            }
        }
        return renderPanelData;
    };
    var handleSegmentStateChange = function (segment) {
        var _a;
        var currentSegment = (_a = activitySegments.response.find(function (item) { return item.segmentID === segment.segmentId; })) !== null && _a !== void 0 ? _a : activitySegments.response[0];
        var activitySegment = {
            completed: currentSegment.completed,
            displayOrder: currentSegment.displayOrder,
            inProgress: currentSegment.inProgress,
            segmentID: currentSegment.segmentID,
            correct: currentSegment.correct
        };
        setSelectedActivitySegment(activitySegment);
    };
    var handleTakeStateChange = function (take) {
        if (take.components != null) {
            var lastCompleteItem = take.components.reverse().find(function (item) { return item.state.completed === true; });
            var inProgressItem = null;
            if (lastCompleteItem !== undefined) {
                var lastItemIndex = take.components.reverse().indexOf(lastCompleteItem);
                inProgressItem = take.components[lastItemIndex + 1];
            }
            setActivitySegments({
                response: take.totalsBySegment.map(function (segment, index) {
                    var components = take.components.filter(function (item) { return item.segmentId === segment.segmentId; });
                    var questionComponent = components.length > 1
                        ? components.find(function (item) { return item.type === "Question"; })
                        : take.components.find(function (item) { return item.segmentId === segment.segmentId; });
                    var completed = questionComponent !== undefined ? questionComponent.state.completed : false;
                    var correct = questionComponent !== undefined ? questionComponent.state.correct : false;
                    var activitySegment = {
                        segmentID: segment.segmentId,
                        completed: completed,
                        correct: correct,
                        displayOrder: index + 1,
                        inProgress: inProgressItem !== null && inProgressItem !== undefined
                            ? inProgressItem.segmentId === segment.segmentId
                            : false
                    };
                    return activitySegment;
                })
            });
        }
    };
    var EducatorListener = {
        onPathLaunched: function (path) {
            if (path) {
                setSegmentTitles(path.segments);
                setPathId(path.pathId);
            }
        },
        onSegmentStateChange: function (segment) {
            if (debugMode) {
                console.log("educator - take state change");
            }
            if (JSON.stringify(currentSegment) !== JSON.stringify(segment)) {
                if (segmentTitles) {
                    var current = segmentTitles.find(function (x) { return x.id === segment.segmentId; });
                    if (current) {
                        segment.title = current.title;
                    }
                }
                setCurrentSegment(segment);
            }
            handleSegmentStateChange(segment);
        },
        onTakeStateChange: function (take) {
            if (debugMode) {
                console.log("educator - take state change");
            }
            handleTakeStateChange(take);
        },
        onSegmentReady: function (data) {
            if (debugMode) {
                console.log("footer - segment ready - " + data);
            }
            if (currentSegment) {
                console.log("Path ID: " +
                    (currentPathId || "empty") +
                    " Segment Title: " +
                    (currentSegment.title || "empty") +
                    " Segment ID: " +
                    (currentSegment.segmentId || "empty"));
            }
        }
    };
    window.ContentReader = __assign(__assign({}, window.ContentReader), EducatorListener);
    return (React.createElement(Container, { className: "educator-content-reader", fluid: true },
        React.createElement(Row, { className: "h-auto w-100 mb-2" },
            React.createElement(Dropdown, { role: "menu", className: "activity-dropdown", onSelect: handleActivitySelect, drop: "down" },
                React.createElement(Dropdown.Toggle, { id: "activity-dropdown" }, selectedActivity.type),
                React.createElement(Dropdown.Menu, null, activities.map(function (item, index) {
                    var selectedItem = index === selectedActivity.index;
                    return (React.createElement(Dropdown.Item, { key: index, active: selectedItem, eventKey: index.toString(), disabled: false }, item.type));
                }))),
            spanishAvailable && (React.createElement(Dropdown, { role: "menu", className: "activity-dropdown mr-n4", onSelect: handleLanguageSelect, drop: "down" },
                React.createElement(Dropdown.Toggle, { id: "activity-dropdown" }, selectedLanguage || ((_b = languages[lessonAttemptLanguage]) !== null && _b !== void 0 ? _b : LanguageEnum.English.toString())),
                React.createElement(Dropdown.Menu, null, languages.map(function (item, index) {
                    var _a;
                    var selectedItem = index ===
                        languages.indexOf(selectedLanguage || ((_a = lessonAttemptLanguage.toString()) !== null && _a !== void 0 ? _a : LanguageEnum.English.toString()));
                    return (React.createElement(Dropdown.Item, { key: index, active: selectedItem, eventKey: item, disabled: false }, item));
                }))))),
        React.createElement("hr", { className: "hr-small" }),
        React.createElement("div", { className: "h-auto w-100 mb-2", ref: carouselDivRef },
            React.createElement(Carousel, { prevIcon: React.createElement(UxIcon, { icon: UxIconEnum.CHEVRON_LEFT, size: UxSizeExtendedEnum.X2 }), nextIcon: React.createElement(UxIcon, { icon: UxIconEnum.CHEVRON_RIGHT, size: UxSizeExtendedEnum.X2 }), interval: null, indicators: false, className: "w-100", activeIndex: carouselIndex, onSelect: handleSelect, wrap: false }, renderCarousel(activitySegments.response))),
        React.createElement(Row, { className: "h-100 w-100" },
            sectionState.showPlayer && sectionState.status === ServiceStatusEnum.LOADING && spinner,
            sectionState.showPlayer && sectionState.status === ServiceStatusEnum.LOADED && (React.createElement(ContentReader, { token: sectionState.payload.token.token, doolittleBaseMediaUrl: doolittleBaseMediaUrl, doolittleBaseApiUrl: doolittleBaseApiUrl, doolittleVersion: "0.6.719", displayCorrectAnswer: true, theme: theme })))));
};
export default EducatorContentReader;
