var secondsInMs = 1000;
var minuteInMs = secondsInMs * 60;
var hourInMs = minuteInMs * 60;
export var getTimeFrameDates = function (days) {
    var startDate = new Date();
    var endDate = new Date();
    startDate.setDate(startDate.getDate() - days);
    startDate.setUTCHours(0, 0, 0, 0);
    endDate.setUTCHours(23, 59, 59, 0);
    return {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
    };
};
export var msToMin = function (time) {
    var minutes = Math.round(time / minuteInMs);
    return minutes > 0 && minutes < 1 ? 1 : minutes;
};
export var msToHHMMSS = function (duration) {
    var hours = Math.floor(duration / hourInMs).toString();
    var minutes = (Math.floor(duration / minuteInMs) % 60).toString();
    var seconds = (Math.floor(duration / secondsInMs) % 60).toString();
    return hours.padStart(2, "0") + ":" + minutes.padStart(2, "0") + ":" + seconds.padStart(2, "0");
};
