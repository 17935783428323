import * as React from "react";
import { UxIcon } from "@wne/ux-component-library";
var NumberOfStudents = function (props) {
    return (React.createElement("div", { className: "csd-num-students" },
        React.createElement("span", { className: "csd-students-icon" },
            React.createElement(UxIcon, { icon: "users" })),
        React.createElement("span", { className: "csd-student-count" },
            props.numStudents,
            " students")));
};
export default NumberOfStudents;
