import React from "react";
import "./sub-header.scss";
var SubHeader = function (_a) {
    var icon = _a.icon, subtitle = _a.subtitle;
    return (React.createElement("div", { className: "scr-nav-sub-heading-container" },
        React.createElement("div", { className: "scr-nav-sub-heading" },
            React.createElement("div", { className: "scr-visual-action-image" },
                React.createElement("img", { src: "" + icon, alt: "" + subtitle })),
            React.createElement("div", null,
                React.createElement("h3", { title: "" + subtitle }, subtitle)))));
};
export default SubHeader;
