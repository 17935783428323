import * as React from "react";
import Chart from "react-google-charts";
var options = {
    pieHole: 0.6,
    is3D: false,
    pieSliceText: "none",
    backgroundColor: "#f5f4f2",
    colors: ["#DE1B0D", "#E27508", "#6C757D", "#268276"],
    title: "",
    titleTextStyle: {
        color: "#6e7075"
    },
    sliceVisibilityThreshold: 0,
    legend: {
        alignment: "center",
        textStyle: { fontSize: "14px", color: "#6e7075" }
    },
    tooltip: { trigger: "none" },
    slices: { 0: { offset: "0.05" }, 1: { offset: "0" }, 2: { offset: "0" }, 3: { offset: "0" } },
    chartArea: {
        left: 20,
        right: 30
    }
};
var StudentDonutChart = function (props) {
    var chartDivKeyboardCapture = function (e) {
        switch (e.key) {
            case "Left":
            case "Up":
            case "ArrowLeft":
            case "ArrowUp": {
                var previousGroup = props.selectedGroup > 0 ? props.selectedGroup - 1 : 3;
                props.setGroup(previousGroup);
                options.slices = { 0: { offset: "0" }, 1: { offset: "0" }, 2: { offset: "0" }, 3: { offset: "0" } };
                options.slices[previousGroup] = { offset: "0.05" };
                e.preventDefault();
                break;
            }
            case "Right":
            case "Down":
            case "ArrowRight":
            case "ArrowDown": {
                var nextGroup = props.selectedGroup < 3 ? props.selectedGroup + 1 : 0;
                props.setGroup(nextGroup);
                options.slices = { 0: { offset: "0" }, 1: { offset: "0" }, 2: { offset: "0" }, 3: { offset: "0" } };
                options.slices[nextGroup] = { offset: "0.05" };
                e.preventDefault();
                break;
            }
            default:
        }
    };
    return (React.createElement("div", { className: "google-pie-chart", tabIndex: 0, role: "document", onKeyDownCapture: chartDivKeyboardCapture },
        React.createElement(Chart, { chartType: "PieChart", width: "100%", height: "100%", data: [
                ["Lessons passing/failing", "no of students"],
                ["2+ lessons not passed (" + props.stats.failedMore + ")", props.stats.failedMore],
                ["1 lesson not passed (" + props.stats.failedOne + ")", props.stats.failedOne],
                ["No lessons completed (" + props.stats.inactive + ")", props.stats.inactive],
                ["Passed All Lessons (" + props.stats.allPassed + ")", props.stats.allPassed]
            ], options: options, chartEvents: [
                {
                    eventName: "select",
                    callback: function (_a) {
                        var _b;
                        var chartWrapper = _a.chartWrapper;
                        var chart = chartWrapper.getChart();
                        var selection = chart.getSelection();
                        var selectedItem = selection[0];
                        var dt = chartWrapper.getDataTable();
                        if (selection.length > 0) {
                            var dataValue = ((_b = dt === null || dt === void 0 ? void 0 : dt.getValue(selectedItem.row, 0)) === null || _b === void 0 ? void 0 : _b.toString()) || "";
                            options.title = dataValue;
                            Object.entries(options.slices).forEach(function (_a) {
                                var key = _a[0], value = _a[1];
                                options.slices = { 0: { offset: "0" }, 1: { offset: "0" }, 2: { offset: "0" }, 3: { offset: "0" } };
                                var groupValue = selectedItem.row;
                                options.slices[groupValue] = { offset: "0.05" };
                            });
                        }
                        else {
                            options.slices = { 0: { offset: "0" }, 1: { offset: "0" }, 2: { offset: "0" }, 3: { offset: "0" } };
                        }
                        props.setGroup(selectedItem.row);
                    }
                }
            ] })));
};
export default StudentDonutChart;
