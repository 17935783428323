var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import { UxTable } from "@wne/ux-component-library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faUsers } from "@fortawesome/pro-solid-svg-icons";
import StudentLessonDetails from "./student-lesson-details";
import { SortState } from "../common/types";
import getNextSortState from "../common/get-next-sort-state";
var SortColumn;
(function (SortColumn) {
    SortColumn[SortColumn["Name"] = 1] = "Name";
    SortColumn[SortColumn["Ltfo"] = 2] = "Ltfo";
    SortColumn[SortColumn["Passed"] = 3] = "Passed";
    SortColumn[SortColumn["ActiveTime"] = 4] = "ActiveTime";
    SortColumn[SortColumn["Default"] = 5] = "Default";
})(SortColumn || (SortColumn = {}));
var StudentDetailTable = /** @class */ (function (_super) {
    __extends(StudentDetailTable, _super);
    function StudentDetailTable(props) {
        var _this = _super.call(this, props) || this;
        _this.getFailedLessons = function (studentId) {
            var stats = _this.props.statsByStudent[studentId];
            return stats.failed;
        };
        _this.getPassedLessons = function (studentId) {
            var stats = _this.props.statsByStudent[studentId];
            return stats.passed;
        };
        _this.getStudentTotalActiveTime = function (studentId) {
            var stats = _this.props.statsByStudent[studentId];
            return stats.activeTimeMins;
        };
        _this.getCircleColor = function (student) { return (_this.getFailedLessons(student.id) === 1 ? "orange-circle" : "red-circle"); };
        _this.getTableRows = function () {
            return _this.props.students.map(function (student) { return ({
                body: (React.createElement(StudentLessonDetails, { studentlessondetails: student.lessonDetails, lessons: _this.props.lessons, getLesson: _this.props.getLesson })),
                studentNameColumn: (React.createElement("div", { className: "detail-name-style" },
                    student.firstName,
                    " ",
                    student.lastName)),
                stfoFailedColumn: (React.createElement("div", { className: "just-align-float-center-basis-100" },
                    React.createElement("div", { className: _this.getFailedLessons(student.id) === 0 ? "no-circle" : "circle " + _this.getCircleColor(student) },
                        React.createElement("div", null, _this.getFailedLessons(student.id))),
                    " ")),
                stfoPassedColumn: (React.createElement("div", { className: "just-align-float-center-basis-100 passed-time-columns" }, _this.getPassedLessons(student.id))),
                stfoActiveTimeColumn: (React.createElement("div", { className: "just-align-float-center-basis-100 passed-time-columns" },
                    _this.getStudentTotalActiveTime(student.id),
                    " Min"))
            }); });
        };
        _this.state = {
            sortState: SortState.NoSort,
            sortColumn: SortColumn.Default
        };
        _this.defaultSort();
        return _this;
    }
    StudentDetailTable.prototype.getHeaders = function () {
        var _this = this;
        var studentNameColumn = (React.createElement("div", { className: "display-flex details-columns-title" },
            React.createElement("span", { className: "padding-before-sort" }, "Student Name"),
            React.createElement("button", { type: "button", title: "Sort", className: "sort-button", onClick: function () { return _this.sortMethod(SortColumn.Name); } },
                React.createElement(FontAwesomeIcon, { icon: faSort }))));
        var stfoFailedColumn = (React.createElement("div", { className: "display-flex-padding-left-5px-center-justify details-columns-title" },
            React.createElement("span", { className: "padding-before-sort" }, "Lessons To Focus On"),
            React.createElement("button", { type: "button", title: "Sort", className: "sort-button", onClick: function () { return _this.sortMethod(SortColumn.Ltfo); } },
                React.createElement(FontAwesomeIcon, { icon: faSort }))));
        var stfoPassedColumn = (React.createElement("div", { className: "display-flex-padding-left-5px-center-justify details-columns-title" },
            React.createElement("span", { className: "padding-before-sort" }, "Passed Lessons"),
            React.createElement("button", { type: "button", title: "Sort", className: "sort-button", onClick: function () { return _this.sortMethod(SortColumn.Passed); } },
                React.createElement(FontAwesomeIcon, { icon: faSort }))));
        var stfoActiveTimeColumn = (React.createElement("div", { className: "display-flex-padding-left-5px-center-justify details-columns-title" },
            React.createElement("span", { className: "padding-before-sort" }, "Total Active Time"),
            React.createElement("button", { type: "button", title: "Sort", className: "sort-button", onClick: function () { return _this.sortMethod(SortColumn.ActiveTime); } },
                React.createElement(FontAwesomeIcon, { icon: faSort }))));
        return [studentNameColumn, stfoFailedColumn, stfoPassedColumn, stfoActiveTimeColumn];
    };
    StudentDetailTable.prototype.sortMethod = function (value) {
        var nextState = getNextSortState(value, this.state);
        this.setState(nextState);
        if (nextState.sortState === SortState.NoSort) {
            this.defaultSort();
        }
        else {
            this.sortType(value, nextState.sortState);
        }
    };
    StudentDetailTable.prototype.sortType = function (value, order) {
        switch (value) {
            case SortColumn.Name:
                this.studentNameSort(order);
                break;
            case SortColumn.Ltfo:
            case SortColumn.Passed:
            case SortColumn.ActiveTime:
                this.sortNumber(value, order);
                break;
            default:
                this.defaultSort();
                break;
        }
    };
    StudentDetailTable.prototype.sortNumber = function (value, order) {
        var _this = this;
        var getColumn = function (studentId) {
            var stats = _this.props.statsByStudent[studentId];
            switch (value) {
                case SortColumn.Ltfo:
                    return stats.failed;
                case SortColumn.Passed:
                    return stats.passed;
                case SortColumn.ActiveTime:
                    return stats.activeTimeMins;
                default:
                    return 0;
            }
        };
        this.props.students.sort(function (a, b) {
            if (getColumn(a.id) !== getColumn(b.id)) {
                if (order === SortState.Ascending) {
                    return getColumn(a.id) - getColumn(b.id);
                }
                return getColumn(b.id) - getColumn(a.id);
            }
            return 0;
        });
    };
    StudentDetailTable.prototype.studentNameSort = function (order) {
        this.props.students.sort(function (a, b) {
            var comparasion = 0;
            switch (order) {
                case SortState.Descending:
                    comparasion = b.lastName.localeCompare(a.lastName);
                    if (comparasion === 0) {
                        comparasion = b.firstName.localeCompare(a.firstName);
                    }
                    return comparasion;
                case SortState.Ascending:
                    comparasion = a.lastName.localeCompare(b.lastName);
                    if (comparasion === 0) {
                        comparasion = a.firstName.localeCompare(b.firstName);
                    }
                    return comparasion;
                default:
                    return 0;
            }
        });
    };
    StudentDetailTable.prototype.defaultSort = function () {
        var _this = this;
        var getFailedLessons = function (studentId) {
            var stats = _this.props.statsByStudent[studentId];
            return stats.failed;
        };
        this.props.students.sort(function (a, b) {
            if (getFailedLessons(a.id) !== getFailedLessons(b.id)) {
                return getFailedLessons(b.id) - getFailedLessons(a.id);
            }
            if (a.lastName !== b.lastName) {
                return a.lastName.localeCompare(b.lastName);
            }
            if (a.lastName === b.lastName) {
                return a.firstName.localeCompare(b.firstName);
            }
            return 0;
        });
    };
    StudentDetailTable.prototype.render = function () {
        return (React.createElement("div", { className: "sfo-details-table-wrapper" },
            React.createElement("div", { className: "student-detail-drop-heading" },
                React.createElement("div", null,
                    "Subject:",
                    React.createElement("span", { className: "bold-font-weight" },
                        " ",
                        this.props.subject)),
                React.createElement("div", null,
                    "TimeFrame: ",
                    React.createElement("span", { className: "bold-font-weight" }, this.props.timeFrame)),
                React.createElement("div", null,
                    React.createElement(FontAwesomeIcon, { icon: faUsers }),
                    " ",
                    React.createElement("span", { className: "bold-font-weight" }, this.props.className))),
            React.createElement(UxTable, { headings: this.getHeaders(), separated: true, items: this.getTableRows(), openIndicator: true })));
    };
    return StudentDetailTable;
}(React.Component));
export default StudentDetailTable;
