var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import * as React from "react";
import UsageHeader from "./usage-header";
import UsageStudents from "./usage-students";
import ViewAllButton from "../common/view-all-button";
import DetailsModal from "../common/details-modal";
import { gradeToInt } from "../common/csd-utils";
import UsageDetail from "./usage-detail";
var Usage = /** @class */ (function (_super) {
    __extends(Usage, _super);
    function Usage(props) {
        var _this = _super.call(this, props) || this;
        _this.showDetailsModal = function () {
            _this.setState({ detailsVisible: true });
        };
        _this.hideDetailsModal = function () {
            _this.setState({ detailsVisible: false });
        };
        _this.handleGroupChange = function (group) {
            _this.setState({ selectedGroup: group });
        };
        _this.usageStatSort = function (studentsByUsageStat) {
            return studentsByUsageStat.sort(function (a, b) {
                if (a.activeTime !== b.activeTime) {
                    return a.activeTime - b.activeTime;
                }
                if (a.student.ilpGrade !== b.student.ilpGrade) {
                    return gradeToInt(a.student.ilpGrade) - gradeToInt(b.student.ilpGrade);
                }
                return a.student.lastName.localeCompare(b.student.lastName);
            });
        };
        _this.UsageDetailsModal = function (visible) {
            if (visible) {
                var studentsByUsageStat = _this.props.model.getStudentsByUsageStat();
                var allUsageStats = __spreadArray(__spreadArray(__spreadArray([], studentsByUsageStat.lessThanThirtyMinutes), studentsByUsageStat.thirtyToFortyFiveMinutes), studentsByUsageStat.greaterThanFortyFiveMinutes).sort(function (a, b) { return a.activeTime - b.activeTime; });
                return (React.createElement(DetailsModal, { title: "Usage Details", body: React.createElement(UsageDetail, { studentUsageStats: allUsageStats, subject: _this.props.subject, timeFrame: _this.props.timeFrame, className: _this.props.className, statsByStudent: _this.props.model.getStudentStatsByStudent() }), onCloseCallback: _this.hideDetailsModal }));
            }
        };
        _this.state = { selectedGroup: 0, detailsVisible: false };
        return _this;
    }
    Usage.prototype.getStudentsOfSelectedUsageStat = function (studentsByUsageStat, usageGroup, hasStudentWithActiveTime) {
        if (hasStudentWithActiveTime) {
            if (usageGroup === 0) {
                return this.usageStatSort(studentsByUsageStat.lessThanThirtyMinutes);
            }
            if (usageGroup === 1) {
                return this.usageStatSort(studentsByUsageStat.thirtyToFortyFiveMinutes);
            }
            if (usageGroup === 2) {
                return this.usageStatSort(studentsByUsageStat.greaterThanFortyFiveMinutes);
            }
        }
        return [];
    };
    Usage.prototype.render = function () {
        var _this = this;
        var studentsByUsageStat = this.props.model.getStudentsByUsageStat();
        // Check if atleast there is one student with some active time. If not, we need to ignore all
        // students as everybody will have an activetime of "0" ( See RAD-9, point 10)
        var hasStudentWithActiveTime = __spreadArray(__spreadArray(__spreadArray([], studentsByUsageStat.greaterThanFortyFiveMinutes), studentsByUsageStat.thirtyToFortyFiveMinutes), studentsByUsageStat.lessThanThirtyMinutes).find(function (ss) { return ss.activeTime > 0; }) !== undefined;
        return (React.createElement("div", { className: "cs-column" },
            React.createElement(UsageHeader, { activeTimeStats: hasStudentWithActiveTime
                    ? studentsByUsageStat
                    : { lessThanThirtyMinutes: [], greaterThanFortyFiveMinutes: [], thirtyToFortyFiveMinutes: [] }, setGroup: this.handleGroupChange, title: this.props.title, selectedGroup: this.state.selectedGroup }),
            React.createElement("div", { className: "table-wrapper" },
                React.createElement(UsageStudents, { studentsUsageStat: this.getStudentsOfSelectedUsageStat(studentsByUsageStat, this.state.selectedGroup, hasStudentWithActiveTime), studentClickCallback: this.props.studentClickCallback }),
                React.createElement(ViewAllButton, { onViewAllButtonClick: function () { return _this.showDetailsModal(); }, buttonText: "VIEW ALL USAGE", key: "USAGE", visibility: hasStudentWithActiveTime })),
            React.createElement("div", { id: "usageDetailsModalWrapper" }, this.UsageDetailsModal(this.state.detailsVisible))));
    };
    return Usage;
}(React.Component));
export default Usage;
