var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import Select from "react-select";
import { getTimeFrameDates } from "../common/datetime-utils";
var TimeFrameSelector = function (props) {
    var options = props.timeFrames.map(function (timeFrame) { return ({ value: timeFrame.id, label: timeFrame.name }); });
    var selectedOption = options.find(function (option) { return option.value === props.activeTimeFrameId; });
    var customStyles = {
        dropdownIndicator: function (base) { return (__assign(__assign({}, base), { color: "#6e7075" // Custom colour
         })); }
    };
    var handleTimeFrameChange = function (value) {
        // Default option Last 7 Days
        var daysToSubstract = 6;
        if (value === 1) {
            daysToSubstract = 0;
        }
        var _a = getTimeFrameDates(daysToSubstract), startDate = _a.startDate, endDate = _a.endDate;
        props.onTimeFrameChange(startDate, endDate, value);
    };
    return (React.createElement("div", { className: "csd-selector timeframe" },
        React.createElement("label", { className: "label", htmlFor: "timeframe-selector" }, "Time Frame:"),
        React.createElement(Select, { styles: customStyles, "aria-label": "timeframe-selector", "aria-labelledby": "timeframe-selector", id: "timeframe-selector", name: "timeframe-selector", className: "csd-react-select", classNamePrefix: "csdrs", value: selectedOption, onChange: function (event) { return handleTimeFrameChange(event.value); }, options: options })));
};
export default TimeFrameSelector;
